import React, { FC, ReactNode } from 'react';
import { Badge } from 'antd';

interface CustomBadgeProps {
  count?: React.ReactNode;
  overflowCount?: number;
  children: ReactNode;
}

export const CustomBadge: FC<CustomBadgeProps> = ({
  count = 0,
  overflowCount = 9,
  children,
}) => {
  return (
    <Badge count={count} overflowCount={overflowCount}>
      {children}
    </Badge>
  );
};
