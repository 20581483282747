import { Avatar } from 'antd';
import { FcManager } from 'react-icons/fc';
import { FC, ReactNode } from 'react';

interface CustomAvatarProps {
  size?: 'small' | 'default' | 'large' | number;
  src?: string;
  icon?: ReactNode;
}

export const CustomAvatar: FC<CustomAvatarProps> = ({
  size = 'large',
  src,
}) => {
  const avatarIcon = src || <FcManager size={50} />;

  return <Avatar size={size} src={avatarIcon} />;
};
