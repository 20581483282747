import {
  Card,
  Message,
  Tabs,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { paymentPageData } from '../../redux/cart/cartSlice.js';
import { useEffect, useState } from 'react';
// import { BsCalendarMonth } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
// import { FcCalendar } from 'react-icons/fc';
import { ApiUtils } from '../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ScrollToTop from '../../components/ScrollToTop.tsx';
import htmlReactParser from 'html-react-parser';

const SubscriptionPage = () => {
  const authUser = useAuthUser();
  const userId = authUser._id;
  // const roles = authUser.rolesType;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [updateUserError, setUpdateUserError] = useState(null);

  const handlePay = (plan) => {
    console.log('payment======', plan);
    dispatch(paymentPageData(plan));
    navigate('/payment');
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const URL = PATHS.SUBSCRIPTION.GET;
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          setUsers(data.response);
        }
      } catch (error) {
        setUpdateUserError(t('error-pls-try-again'));
        console.log(error.message);
      }
    };
    // if (roles.includes('admin')) {
    fetchUsers();
    <ScrollToTop />;
    // }
  }, [userId]);

  const monthly = users.filter((plan) => plan.duration === '2');
  const yearly = users.filter((plan) => plan.duration === '1');

  const monthlyItems = monthly.map((plan, index) => (
    <Col key={index} span={8}>
      <Card className="">
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
          {plan.name}
        </h5>
        <div className="flex items-baseline text-gray-900 dark:text-white">
          <span className="text-3xl font-semibold">$</span>
          <span className="text-5xl font-extrabold tracking-tight">
            {plan.price}
          </span>
          <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
            /month
          </span>
        </div>
        <ul className="my-7 space-y-5">
          <li key={index} className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
              {htmlReactParser(plan.content)}
            </span>
          </li>
        </ul>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
          onClick={() => handlePay(plan)}
        >
          {t('choose-plan')}{' '}
        </button>
      </Card>
    </Col>
  ));

  const yearlyItems = yearly.map((plan, index) => (
    <Col key={index} span={8}>
      <Card className="">
        <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
          {plan.name}
        </h5>
        <div className="flex items-baseline text-gray-900 dark:text-white">
          <span className="text-3xl font-semibold">$</span>
          <span className="text-5xl font-extrabold tracking-tight">
            {plan.price}
          </span>
          <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
            /yearly
          </span>
        </div>
        <ul className="my-7 space-y-5">
          <li key={index} className="flex space-x-3">
            <svg
              className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
              {htmlReactParser(plan.content)}
            </span>
          </li>
        </ul>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
          onClick={() => handlePay(plan)}
        >
          {t('choose-plan')}{' '}
        </button>
      </Card>
    </Col>
  ));

  const items = [
    {
      key: '1',
      label: t('monthly'),
      children: (
        // Mapping monthly plans to the first tab
        <Row gutter={16}>{monthlyItems}</Row>
      ),
      // icon: <BsCalendarMonth />,
    },
    {
      key: '2',
      children: (
        // Mapping yearly plans to the second tab
        <Row gutter={16}>{yearlyItems}</Row>
      ),
      label: t('yearly'),
      // icon: <FcCalendar />,
    },
  ];

  return (
    <main className="px-16 pb-16 ">
      <FloatButton.BackTop />
      <Tabs defaultActiveKey="1" items={items} type="card" />
      {updateUserError && (
        <Message className="mt-5" type="error" message={updateUserError} />
      )}
    </main>
  );
};

export default SubscriptionPage;
