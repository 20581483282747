import {
  Spinner,
  Popconfirm,
  Table,
  // InputNumber,
  Image,
  Message,
} from '../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ApiUtils } from '../../utils/api.tsx';
import { useSelector } from 'react-redux';
import { addcartCount, addcart } from '../../redux/cart/cartSlice.js';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { ScrollToTop } from '../../utils/reUsable/index.tsx';

export default function ViewCart() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const dispatch = useDispatch();
  const { cartCount } = useSelector((state) => state.cart);
  const [cartList, setCartlist] = useState([]);
  const [publishError, setPublishError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const fetchCartList = async () => {
    setLoading(true);
    try {
      const URL = `${PATHS.WISHLIST.LIST}?userId=${userId}`;
      const data = await ApiUtils(URL);

      if (data.status === 200) {
        setCartlist(data.response);
        dispatch(addcart(data.response));
        setTotalCount(data.totalCount);
      } else {
        dispatch(addcartCount(0));
        console.log('error in get post', data.message);
        setPublishError(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error in get post', error.message);
      setPublishError(error.message);
    }
  };
  useEffect(() => {
    fetchCartList();
    <ScrollToTop />;
  }, [userId]);

  const handleShowMore = async (page) => {
    try {
      setPageCount(page);
      setLoading(true);

      const URL = `${PATHS.WISHLIST.LIST}?limit=10&page=${page}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setCartlist(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleDeletePost = async (id) => {
    try {
      let currentValue = cartCount;
      const payLoad = { _id: id };
      const URL = PATHS.WISHLIST.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);

      if (data.status !== 200) {
        console.log('error in delete post', data.message);
        setPublishError(data.message);
      } else {
        dispatch(addcartCount(currentValue - 1));
        fetchCartList();
      }
    } catch (error) {
      console.log(error.message);
      console.log('error in delete post', error.message);
      setPublishError(error.message);
    }
  };

  if (loading) {
    return (
      <div className="flex relative  justify-center items-center h-screen">
        <Spinner size="xl" />
      </div>
    );
  }

  const columns = [
    {
      title: t('productImage'),
      dataIndex: '',
      key: 'image',
      render: (text, record) => (
        <Link to={`/post/${record.postId._id}`}>
          <Image
            src={record.postId.imageUrl[0]}
            alt={record.postId.title}
            className="w-40 h-20 object-cover bg-gray-500"
          />
        </Link>
      ),
    },
    {
      title: t('product'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Link
          className="font-medium text-gray-900 dark:text-gray-900"
          to={`/post/${record.postId._id}`}
        >
          {record.postId.title}
        </Link>
      ),
    },

    {
      title: t('category'),
      dataIndex: ['categoryId', 'name'],
      key: ['categoryId', 'name'],
    },
    // {
    //   title: t('price'),
    //   dataIndex: ['postId', 'price'],
    //   key: ['postId', 'price'],
    // },
    {
      title: 'Quantity',
      dataIndex: ['postId', 'quantity'],
      key: ['postId', 'quantity'],
    },
    {
      title: t('delete'),
      key: '_id',
      render: (text, record) => (
        <Popconfirm
          title={t('remove-from-wishlist?')}
          onConfirm={() => handleDeletePost(record._id)}
          okText={t('yes-im-sure')}
          cancelText={t('no-cancel')}
        >
          <RiDeleteBin6Line
            size={20}
            color="red"
            style={{ cursor: 'pointer' }}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <main className="p-3 flex flex-col max-w-6xl mx-auto min-h-screen">
      <>
        {cartList?.length > 0 ? (
          <Table
            dataSource={cartList}
            columns={columns}
            pagination={{
              pageSize: 10,
              total: totalCount,
              current: pageCount,
              onChange: (page) => handleShowMore(page),
            }}
            loading={loading}
          />
        ) : (
          <div className="flex relative  justify-center items-center h-screen">
            <p>{t('you-have-no-wishlist-yet')}</p>
          </div>
        )}
        {publishError && <Message successMessage={publishError} type="error" />}
      </>
    </main>
  );
}
