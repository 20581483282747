import { FC } from 'react';
import { Image, Carousel } from 'antd';
import DefaultImage from '../../assets/images/default-user.png';

interface CustomImagePreviewGroupScrollProps {
  type?: string;
  className?: string;
  images: string[];
  alt?: string;
  width?: number;
  height?: number;
}

export const CustomImagePreviewGroupScroll: FC<
  CustomImagePreviewGroupScrollProps
> = ({
  type = 'default',
  className,
  images = [DefaultImage],
  alt = '',
  width = 200,
  height = 200,
}) => {
  return (
    <div className="flex flex-wrap m-4">
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <div className="m-4">
          {images.length > 1 ? (
            <Carousel autoplay={true} dotPosition="left" direction="horizontal">
              {images.map((src, index) => (
                <Image
                  key={index}
                  type={type}
                  className={className}
                  src={src}
                  alt={alt}
                  height={height}
                  width={width}
                />
              ))}
            </Carousel>
          ) : (
            <Image
              type={type}
              className={className}
              src={images[0]}
              alt={alt}
              height={height}
              width={width}
            />
          )}
        </div>
      </Image.PreviewGroup>
    </div>
  );
};
