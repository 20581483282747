import Header from './Header.jsx';
import Footer from './Footer.jsx';
import DashSidebar from './DashSidebar.jsx';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';
import { Layout } from 'antd';
import MaintenancePage from '../pages/SpecialPages/MaintenancePage.jsx';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import ScrollToTop from '../components/ScrollToTop.tsx';
import Breadcrumb from './Breadcrumb.jsx';
import Routes from '../routes/index.jsx';
// import LanguagePrompt from '../utils/languagePrompt';

const layout = () => {
  const [maintenanceEnabled] = useState(false);

  if (maintenanceEnabled) {
    return <MaintenancePage />;
  }
  const { Content } = Layout;

  const store = createStore({
    authName: '_auth',
    authType: 'localstorage',
    cookieDomain: window.location.hostname,
    cookieSecure: window.location.protocol === 'https:',
  });
  return (
    <AuthProvider store={store}>
      <BrowserRouter>
        <Header />
        <Layout>
          <DashSidebar />
          <Layout style={{ position: 'aboslute', width: '100%' }}>
            <Content style={{ margin: '0px' }}>
              <ScrollToTop />
              <Breadcrumb />
              <div
                style={{
                  minHeight: '360px',
                }}
              >
                <Routes />
              </div>
            </Content>
            <Footer />
          </Layout>
        </Layout>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default layout;
