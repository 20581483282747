import { MdDeleteOutline } from 'react-icons/md';
import {
  Table,
  Select,
  Message,
  Popconfirm,
  Tag,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.tsx';
import { STATUSLIST } from '../../../utils/constants/index.ts';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { CiEdit } from 'react-icons/ci';
import ScrollToTop from '../../../components/ScrollToTop.tsx';
import { useNavigate } from 'react-router-dom';

const DashRoles = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const translatedSTATUSLIST = STATUSLIST(t);

  const roles = authUser.rolesType;
  const userId = authUser._id;

  const [rolesList, setRolesList] = useState([]);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState();

  useEffect(() => {
    const fetchrolesList = async () => {
      try {
        setLoading(true);
        let URL;
        roles?.includes('admin')
          ? (URL = PATHS.PAYMENTS.GET + `?status=${filter}`)
          : (URL = PATHS.PAYMENTS.GET + `?status=${filter}&userId=${userId}`);
        const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
        <ScrollToTop />;

        if (data.status === 200) {
          setRolesList(data.response);
          setTotalCount(data.totalCount);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    };
    fetchrolesList();
  }, [userId, filter]);

  const handleShowMore = async (page, pageSize) => {
    try {
      setPageCount(page);
      setLoading(true);
      setPageSize(pageSize);

      const URL =
        PATHS.ROLES.GET +
        `?limit=10&status=${filter}&page=${page}&limit=${pageSize}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setRolesList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.ROLES.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setRolesList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        console.log('error in del temple=====', data);
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del temple1=====', error);
      setSuccessMessage(null);
      setErrorMessage(t('error-pls-try-again'));
    }
  };

  const handleStatusFilterChange = (pagination, filters, sorter) => {
    console.log('status1=====', pagination);
    console.log('status2=====', filters);
    console.log('status3=====', sorter);

    // Assuming 'status' is the key for the filter;
    // filters.status will be an array of selected filter values
    const statusFilterValue = filters.status ? filters.status[0] : null;
    setSelectedStatus(statusFilterValue);
  };

  useEffect(() => {
    if (selectedStatus !== undefined) {
      // Check for undefined to allow resetting/clearing the filter as well
      // Update the URL. Modify to fit your URL schema
      navigate(`?status=${selectedStatus}`);
    }
  }, [selectedStatus, navigate]);

  const columns = [
    {
      title: t('createdOn'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: t('username'),
      dataIndex: ['userId', 'first_name'],
      key: ['userId', 'first_name'],
      sorter: (a, b) => a.type.localeCompare(b.type), // Basic alphabetical sort
    },
    {
      title: t('planName'),
      dataIndex: ['subscriptionId', 'name'],
      key: ['subscriptionId', 'name'],
    },
    {
      title: t('planId'),
      dataIndex: ['subscriptionId', '_id'],
      key: ['subscriptionId', '_id'],
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: t('active'), value: 1 },
        { text: t('inactive'), value: 2 },
        { text: t('deleted'), value: 3 },
      ],
      onFilter: (value, record) => record.status === value,
      render: (text) =>
        text === 1 ? (
          <Tag active="1">{t('active')}</Tag>
        ) : text === 2 ? (
          <Tag active="2">{t('inactive')}</Tag>
        ) : (
          <Tag active="3">{t('deleted')}</Tag>
        ),
    },
    {
      title: t('edit'),
      key: 'edit',
      render: (text, record) => (
        <Link
          to={`/update-roles/${record._id}`}
          className="text-teal-500 hover:underline"
        >
          <CiEdit size={20} color="blue" />
        </Link>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (text, record) => (
        <Popconfirm
          title={t('are-you-sure-you-want-to-delete-this-module')}
          onConfirm={() => handleDeleteUser(record._id)}
          okText={t('yes-im-sure')}
          cancelText={t('no-cancel')}
        >
          <MdDeleteOutline size={20} color="red" />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <div className="w-full flex flex-row justify-between my-1 py-2 mb-3">
        <Select
          required={true}
          onChange={(e) => setFilter(e)}
          options={translatedSTATUSLIST}
          placeholder={t('select')}
          label="name"
          className={'w-1/4'}
        />
      </div>

      <Table
        dataSource={rolesList}
        columns={columns}
        rowKey={(record) => record._id}
        onChange={handleStatusFilterChange}
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: pageCount,
          onChange: (page, pageSize) => handleShowMore(page, pageSize),
        }}
        loading={loading}
      />

      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default DashRoles;
