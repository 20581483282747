import React from 'react';
import { Image } from 'antd';
import DefaultImage from '../../assets/images/default-user.png';

interface CustomImagePreviewGroup {
  className?: string;
  images: string[] | undefined;
  alt?: string;
  width?: number;
  height?: number;
}

export const CustomImagePreviewGroup: React.FC<CustomImagePreviewGroup> = ({
  className,
  images = [DefaultImage],
  alt = '',
  width = 200,
  height = 200,
}) => {
  return (
    <div className="flex flex-wrap m-4">
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`Current index: ${current}, Previous index: ${prev}`),
        }}
      >
        {Array.isArray(images)
          ? images.map((src, index) => (
              <div key={index} className="p-5">
                <Image
                  className={className}
                  src={src}
                  alt={alt}
                  height={height}
                  width={width}
                />
              </div>
            ))
          : images && (
              <Image
                className={className}
                src={images}
                alt={alt}
                height={height}
                width={width}
              />
            )}
      </Image.PreviewGroup>
    </div>
  );
};
