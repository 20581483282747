const METHOD_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  UPDATE: 'POST',
  DELETE: 'DELETE',
  PUT: 'PUT',
};

export default METHOD_TYPES;
