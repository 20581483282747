export const CityList = [
  { _id: 'Mumbai', value: [19.076, 72.8777] },
  { _id: 'Delhi', value: [28.6139, 77.209] },
  { _id: 'Bangalore', value: [12.9716, 77.5946] },
  { _id: 'Kolkata', value: [22.5726, 88.3639] },
  { _id: 'Chennai', value: [13.0827, 80.2707] },
  { _id: 'Hyderabad', value: [17.385, 78.4867] },
  { _id: 'Pune', value: [18.5204, 73.8567] },
  { _id: 'Ahmedabad', value: [23.0225, 72.5714] },
  { _id: 'Jaipur', value: [26.9124, 75.7873] },
  { _id: 'Surat', value: [21.1702, 72.8311] },
  { _id: 'Lucknow', value: [26.8467, 80.9462] },
  { _id: 'Kanpur', value: [26.4499, 80.3319] },
  { _id: 'Nagpur', value: [21.1458, 79.0882] },
  { _id: 'Indore', value: [22.7196, 75.8577] },
  { _id: 'Thane', value: [19.2183, 72.9781] },
  { _id: 'Bhopal', value: [23.2599, 77.4126] },
  { _id: 'Visakhapatnam', value: [17.6868, 83.2185] },
  { _id: 'Pimpri-Chinchwad', value: [18.6279, 73.8009] },
  { _id: 'Patna', value: [25.5941, 85.1376] },
  { _id: 'Vadodara', value: [22.3072, 73.1812] },
  { _id: 'Ghaziabad', value: [28.6692, 77.4538] },
  { _id: 'Ludhiana', value: [30.901, 75.8573] },
  { _id: 'Agra', value: [27.1767, 78.0081] },
  { _id: 'Nashik', value: [20.0112, 73.7909] },
  { _id: 'Faridabad', value: [28.4089, 77.3178] },
  { _id: 'Meerut', value: [28.9845, 77.7064] },
  { _id: 'Rajkot', value: [22.3039, 70.8022] },
  { _id: 'Kalyan-Dombivli', value: [19.2355, 73.1309] },
  { _id: 'Vasai-Virar', value: [19.391, 72.8397] },
  { _id: 'Varanasi', value: [25.3176, 82.9739] },
  { _id: 'Srinagar', value: [34.0836, 74.7973] },
  { _id: 'Aurangabad', value: [19.8762, 75.3433] },
  { _id: 'Dhanbad', value: [23.7957, 86.4304] },
  { _id: 'Amritsar', value: [31.634, 74.8723] },
  { _id: 'Navi Mumbai', value: [19.033, 73.0297] },
  { _id: 'Allahabad', value: [25.4358, 81.8463] },
  { _id: 'Ranchi', value: [23.3441, 85.3096] },
  { _id: 'Howrah', value: [22.5958, 88.2636] },
  { _id: 'Coimbatore', value: [11.0168, 76.9558] },
  { _id: 'Jabalpur', value: [23.1815, 79.9864] },
  { _id: 'Gwalior', value: [26.2183, 78.1828] },
  { _id: 'Vijayawada', value: [16.5062, 80.648] },
  { _id: 'Jodhpur', value: [26.2389, 73.0243] },
  { _id: 'Madurai', value: [9.9252, 78.1198] },
  { _id: 'Raipur', value: [21.2514, 81.6296] },
  { _id: 'Kota', value: [25.2138, 75.8648] },
  { _id: 'Guwahati', value: [26.1445, 91.7362] },
  { _id: 'Chandigarh', value: [30.7333, 76.7794] },
  { _id: 'Solapur', value: [17.6599, 75.9064] },
  { _id: 'Hubli-Dharwad', value: [15.3647, 75.1239] },
  { _id: 'Moradabad', value: [28.8389, 78.7768] },
  { _id: 'Mysore', value: [12.2958, 76.6394] },
  { _id: 'Gurgaon', value: [28.4595, 77.0266] },
  { _id: 'Aligarh', value: [27.8974, 78.088] },
  { _id: 'Jalandhar', value: [31.326, 75.5762] },
  { _id: 'Bhubaneswar', value: [20.2961, 85.8245] },
  { _id: 'Salem', value: [11.6643, 78.146] },
  { _id: 'Warangal', value: [17.9784, 79.6006] },
  { _id: 'Guntur', value: [16.3067, 80.4365] },
  { _id: 'Bhiwandi', value: [19.2813, 73.0483] },
  { _id: 'Saharanpur', value: [29.9679, 77.5452] },
  { _id: 'Gorakhpur', value: [26.7606, 83.3732] },
  { _id: 'Bikaner', value: [28.0229, 73.3119] },
  { _id: 'Amravati', value: [20.9333, 77.75] },
  { _id: 'Noida', value: [28.5355, 77.391] },
  { _id: 'Jamshedpur', value: [22.8046, 86.2029] },
  { _id: 'Bhilai', value: [21.1938, 81.3509] },
  { _id: 'Cuttack', value: [20.4625, 85.8828] },
  { _id: 'Firozabad', value: [27.1591, 78.3957] },
  { _id: 'Kochi', value: [9.9312, 76.2673] },
  { _id: 'Bhavnagar', value: [21.7645, 72.1519] },
  { _id: 'Dehradun', value: [30.3165, 78.0322] },
  { _id: 'Durgapur', value: [23.5204, 87.3119] },
  { _id: 'Asansol', value: [23.6739, 86.9524] },
  { _id: 'Nanded', value: [19.1383, 77.3218] },
  { _id: 'Kolhapur', value: [16.705, 74.2433] },
  { _id: 'Ajmer', value: [26.4499, 74.6399] },
  { _id: 'Gulbarga', value: [17.3297, 76.8376] },
  { _id: 'Jamnagar', value: [22.4707, 70.0577] },
  { _id: 'Ujjain', value: [23.1765, 75.7885] },
  { _id: 'Loni', value: [28.7536, 77.2882] },
  { _id: 'Siliguri', value: [26.7271, 88.3953] },
  { _id: 'Jhansi', value: [25.4484, 78.5685] },
  { _id: 'Ulhasnagar', value: [19.2215, 73.1645] },
  { _id: 'Jammu', value: [32.7266, 74.857] },
  { _id: 'Sangli-Miraj & Kupwad', value: [16.8606, 74.5641] },
  { _id: 'Mangalore', value: [12.9141, 74.856] },
  { _id: 'Erode', value: [11.3428, 77.7286] },
  { _id: 'Belgaum', value: [15.8497, 74.4977] },
  { _id: 'Ambattur', value: [13.1143, 80.148] },
];
