import { FC } from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface LabelComponentProps {
  value: string;
  isRequired?: boolean;
  color?: string;
}

export const LabelComponent: FC<LabelComponentProps> = ({
  value,
  isRequired = true,
  color,
}) => {
  return (
    <Text className="block text-sm font-medium" style={{ color }}>
      {' '}
      {/* Use the color prop */}
      {value} {isRequired && <span style={{ color: 'red' }}>*</span>}
    </Text>
  );
};
