/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, FC } from 'react';
import {
  Upload,
  Button,
  Message,
  ImagePreviewGroup,
} from '../antd-components/index';
import { FaUpload, FaImages } from 'react-icons/fa';
import { ApiUtils } from '../api.tsx';
import { useTranslation } from 'react-i18next';
import METHOD_TYPES from '../constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import Compressor from 'compressorjs';

interface ImageUploadProps {
  formData: {
    imageUrl?: string[];
  };
  setFormData: (value: string | null) => void;
  loading: (isLoading: boolean) => void;
  type?: 'single' | 'multiple';
  listType?: 'text' | 'picture' | 'picture-card';
}

const ImageUpload: FC<ImageUploadProps> = ({
  formData,
  setFormData,
  loading,
  type = 'single',
  listType,
}) => {
  const [file, setFile] = useState<File[] | null>([]);
  const [imageUploadError, setImageUploadError] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSelectUploadImage = async (info: any) => {
    if (isUploading) return;

    const { file, fileList } = info;
    if (file.status === 'removed') {
      setFile(null);
      return;
    }

    setIsUploading(true);
    loading(true);
    const filteredFiles = fileList.filter((f: any) => {
      const isLt10M = f.size / 1024 / 1024 < 11;
      if (!isLt10M) {
        setImageUploadError(t('file must be smaller than 10MB'));
        return false;
      }
      return true;
    });

    if (type === 'multiple' && filteredFiles.length > 10) {
      setImageUploadError(t('cannot upload more than 10 images'));
      setIsUploading(false);
      loading(false);
      return;
    }

    if (filteredFiles.length === 0) {
      setImageUploadError(t('please-select-an-image'));
      setIsUploading(false);
      loading(false);
      return;
    }

    setFile(filteredFiles);
    setImageUploadError(null);
    setIsUploading(false);
    loading(false);
  };

  const onRemoveImage = () => {
    setFile(null);
  };

  const handleUploadImage = async () => {
    setIsUploading(true);
    if (!file || file.length === 0) {
      setImageUploadError(t('please-select-a-file'));
      setIsUploading(false);
      return;
    }

    const formDataUpload = new FormData();

    try {
      // Process each file for compression
      const compressAndAppend = async (file: any) => {
        return new Promise<void>((resolve, reject) => {
          new Compressor(file.originFileObj, {
            quality: 0.8, // Adjust compression quality
            success: (compressedResult) => {
              formDataUpload.append('file', compressedResult, file.name);
              resolve();
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      };

      // Await all compressions & append operations
      await Promise.all(file.map((f) => compressAndAppend(f)));

      const data = await ApiUtils(
        PATHS.IMAGE.POST,
        formDataUpload,
        METHOD_TYPES.POST,
        'multipart/form-data',
      );

      if (data.status === 200) {
        const UploadImage =
          type !== 'multiple'
            ? data.response[0].location
            : data.response.map((image: { location: any }) => image.location);
        setFormData(UploadImage);
        setIsUploading(false);
      } else {
        setIsUploading(false);
        setImageUploadError(t('image-upload-failed'));
      }
    } catch (error) {
      console.log('err in upload image function', error);
      setImageUploadError(t('image-upload-failed'));
    } finally {
      setIsUploading(false);
    }
  };

  const onDeleteImage = async (image: string) => {
    const payload = {
      image: image,
    };
    const URL = PATHS.IMAGE.DELETE;
    const data = await ApiUtils(URL, payload, METHOD_TYPES.DELETE);
    if (data.status === 200) {
      setFormData(null);
    }
  };

  return (
    <div>
      <div className="flex gap-4 items-center justify-between border-4 border-teal-500 border-dotted p-3">
        <Upload
          accept="image/*"
          onChange={handleSelectUploadImage}
          listType={listType}
          fileList={file}
          multiple={type === 'multiple'}
          customRequest={() => {}}
          beforeUpload={() => false}
          onRemove={onRemoveImage}
        >
          <Button
            disabled={isUploading || formData?.imageUrl ? true : false}
            icon={<FaImages />}
          >
            {type === 'multiple' ? t('select-images') : t('select-image')}
          </Button>
        </Upload>
        <Button
          disabled={isUploading || formData?.imageUrl ? true : false}
          onClick={handleUploadImage}
          icon={<FaUpload />}
          loading={isUploading}
        >
          {t('upload-image')}
        </Button>
      </div>
      {imageUploadError && (
        <Message type="error" successMessage={imageUploadError} />
      )}

      <ImagePreviewGroup
        onDeleteImage={onDeleteImage}
        images={formData?.imageUrl}
      />
    </div>
  );
};

export default ImageUpload;
