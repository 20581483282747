import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { paymentPageData } from '../../redux/cart/cartSlice.js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from '../../components/CheckOutForm.tsx';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import { ApiUtils } from '../../utils/api.tsx';
import { useState } from 'react';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const PaymentPage = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const email = authUser.email;

  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe(
    'pk_test_51OvCigSHWotJMhkyvFdhIteOONcX3TgBOeIaHFA6jw1KVSGiknD4yUDUtDUrWBOEvEQMJsdGJFrpRPf44T4T9mLk001lsyTU5z',
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subcriptionPlan } = useSelector((state) => state.cart);
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProblem, setIsProblem] = useState(false);
  const handlePay = (plan) => {
    dispatch(paymentPageData(plan));
    navigate('/payment');
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const options = {
          paymentMethodType: 'card',
          amount: subcriptionPlan?.price,
          subscriptionId: subcriptionPlan?._id,
          email: email,
          currency: 'INR',
        };
        const URL = PATHS.PAYMENTS.CREATEINTENT;
        const data = await ApiUtils(URL, options, METHOD_TYPES.POST);
        // set the client secret in state
        if (data.status === 200) {
          let clientSecret = data.clientSecret;
          setClientSecret({ clientSecret, theme: 'stripe' });
          setIsLoading(false);
        } else {
          console.log('Error creating payment intent', data);
          setIsProblem('Error creating payment intent');
        }
        console.log('payment response', data);
      } catch (e) {
        console.log('error in payment intent', e);
      }
    };
    fetchUsers();
  }, [subcriptionPlan]);

  console.log('sub====', subcriptionPlan);
  return (
    <main className="px-16 py-16  grid gap-20  grid-cols-2 mx-auto justify-center">
      <div className="max-w-sm">
        {isLoading ? (
          // render a loading spinner or a placeholder component
          <div>Loading...</div>
        ) : isProblem ? (
          <p>
            {t('error-creating-payment-intent')} Try refreshing or contact
            support.
          </p>
        ) : (
          <Elements stripe={stripePromise} options={clientSecret}>
            <CheckoutForm
              clientSecret={clientSecret}
              subscriptionId={subcriptionPlan?._id}
              email={email}
            />
          </Elements>
        )}
      </div>
      {[subcriptionPlan].map((plan, index) => (
        <Card key={index} className="max-w-sm">
          <h5 className="mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
            {plan.title}
          </h5>
          <div className="flex items-baseline text-gray-900 dark:text-white">
            <span className="text-3xl font-semibold">$</span>
            <span className="text-5xl font-extrabold tracking-tight">
              {plan.price}
            </span>
            <span className="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
              /month
            </span>
          </div>
          <ul className="my-7 space-y-5">
            <li key={index} className="flex space-x-3">
              <svg
                className="h-5 w-5 shrink-0 text-cyan-600 dark:text-cyan-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">
                <p
                  className="text-gray-500 text-xs sm:text-sm"
                  dangerouslySetInnerHTML={{ __html: plan.content }}
                ></p>
              </span>
            </li>
          </ul>
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-lg bg-cyan-600 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-cyan-700 focus:outline-none focus:ring-4 focus:ring-cyan-200 dark:focus:ring-cyan-900"
            onClick={() => handlePay(plan)}
          >
            {t('choosed-plan')}{' '}
          </button>
        </Card>
      ))}
    </main>
  );
};

export default PaymentPage;
