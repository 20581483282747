import { FC } from 'react';
import { Skeleton, Row, Col } from 'antd';

interface CustomSkeletonProps {
  active?: boolean;
  count: number;
}

export const CustomSkeleton: FC<CustomSkeletonProps> = ({
  active = true,
  count = 9,
}) => {
  const renderSkeletons = () => {
    const skeletons = [];
    for (let i = 0; i < count; i++) {
      skeletons.push(
        <Col span={8} key={i}>
          <Skeleton
            active={active}
            avatar={{ shape: 'square', size: 100 }}
            paragraph={{ rows: 4 }}
          />
        </Col>,
      );
    }
    return skeletons;
  };

  return (
    <div className="m-5">
      <Row gutter={[36, 16]}>{renderSkeletons()}</Row>
    </div>
  );
};
