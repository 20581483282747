import { useState, FC, FormEvent } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ApiUtils } from '../utils/api';
import PATHS from '../utils/constants/Paths.tsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useNavigate } from 'react-router-dom';

interface CheckoutFormProps {
  clientSecret: {
    clientSecret: string;
  };
  subscriptionId: string;
  email: string;
}

export const CheckoutForm: FC<CheckoutFormProps> = ({
  clientSecret,
  subscriptionId,
  email,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const authUser = useAuthUser();
  const userId = authUser?._id;
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userId) {
      if (elements == null || stripe == null) {
        return;
      }
      setLoading(true);
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const result = await stripe.confirmCardPayment(
          clientSecret.clientSecret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: 'John Doe',
                address: {
                  city: 'Kingston',
                  country: 'US',
                  line1: ' 132, My Street',
                  line2: 'first floor',
                  postal_code: '12401',
                  state: 'New York',
                },
              },
            },
          },
        );
        setLoading(true); // This might be an error because setLoading(true) is repeated before checking result.error
        if (result.error) {
          console.log('error in result', result.error);
          setErrorMessage(result.error.message);
        } else {
          let payload = {
            stripe: result.paymentIntent,
            subscriptionId,
            email,
          };
          const URL = PATHS.PAYMENTS.CREATETRANSACTION;
          const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
          if (data.status === 200) {
            setLoading(false);
            navigate('/payment-success');
          } else {
            setErrorMessage(data.message);
            setLoading(false);
            navigate('/payment-failed');
          }
        }
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="px-4">
      <CardElement />
      <div className="flex justify-center py-3">
        <button
          className="bg-blue-600 hover:bg-blue-700"
          disabled={loading || !stripe || !elements}
        >
          {loading ? 'Loading...' : 'Pay now'}
        </button>
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};
