// import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Button, Result } from 'antd';
import { doSignOut } from '../utils/supabase/auth.ts';
import useSignOut from 'react-auth-kit/hooks/useSignOut';

export default function OnlyAdminPrivateRoute() {
  // const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const roles = authUser?.rolesType;
  const signOut = useSignOut();
  const redirectToHome = async () => {
    console.log('button click');
    await doSignOut();
    signOut();
    return navigate('/sign-in');
  };
  return roles?.includes('admin') ? (
    <Outlet />
  ) : (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          className="ml-1/2 flex "
          onClick={() => redirectToHome()}
        >
          Back Home
        </Button>
      }
    />
  );
}
