import {
  ImagePreviewGroup,
  Button,
  Spinner,
  Message,
  Image,
  Typography,
  Avatar,
  Progress,
} from '../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import {
  Link,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { FaRegHeart } from 'react-icons/fa';
import PostCard from '../../components/PostCard.tsx';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { addcartCount, addcart } from '../../redux/cart/cartSlice.js';
import { SignInRiderect } from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import ScrollToTop from '../../components/ScrollToTop.tsx';
import htmlReactParser from 'html-react-parser';

export default function PostPage() {
  const authUser = useAuthUser();
  const userId = authUser?._id;
  const { t } = useTranslation();
  const { postSlug } = useParams();
  const [searchParams] = useSearchParams();

  // Get the value of the 'status' query parameter
  const status = searchParams.get('status');
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { cartDetails } = useSelector((state) => state.cart);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [post, setPost] = useState(null);
  const [recentPosts, setRecentPosts] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const [successMessage, setsuccessMessage] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        let URL;
        <ScrollToTop />;
        if (status) {
          URL = `${PATHS.POSTS.INDIVIDUAL}?_id=${postSlug}&status=${status}`;
        } else {
          URL = `${PATHS.POSTS.INDIVIDUAL}?_id=${postSlug}`;
        }
        const data = await ApiUtils(URL);

        if (data.status !== 200) {
          setError(true);
          setLoading(false);
          return;
        } else {
          setPost(data.posts);
          setLoading(false);
          setError(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
      }
    };
    if (postSlug) {
      fetchPost();
    }
  }, [postSlug]);

  useEffect(() => {
    try {
      const fetchRecentPosts = async () => {
        let queryParams = '';

        if (postSlug) {
          queryParams = `?postIdDelete=${postSlug}&limit=5`;
        }
        const URL = `${PATHS.POSTS.RECPOST}${queryParams}`;
        const data = await ApiUtils(URL);

        if (data.status === 200) {
          setRecentPosts(data.posts);
        }
      };
      fetchRecentPosts();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const addToCart1 = async () => {
    try {
      const formData = {
        userId: userId,
        postId: post._id,
        categoryId: post.categoryId._id,
        status: 1,
      };
      const URL = PATHS.WISHLIST.ADD;
      const data = await ApiUtils(URL, formData, METHOD_TYPES.POST);
      if (data.status === 200) {
        setsuccessMessage(t('wishlist-added'));
        const URL = `${PATHS.WISHLIST.LIST}?userId=${userId}`;
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          dispatch(addcart(data.response));
          dispatch(addcartCount(data.totalCount));
        }
      } else {
        console.log('error in adding to cart=====', data);
        setPublishError(data.message);
      }
    } catch (e) {
      console.log('error in adding1 to cart=====', e);
      setPublishError(e.message);
    }
  };

  const redirect = () => {
    const currentUrl = location.pathname;
    dispatch(SignInRiderect(currentUrl));
    navigate('/sign-in');
  };

  if (loading)
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner size="xl" />
      </div>
    );
  // console.log('cartDetails', cartDetails);
  const postExists =
    cartDetails &&
    cartDetails.some((postObj) => postObj.postId?._id === postSlug);
  // console.log('cartDetails', postExists);
  return (
    <main className="p-3 flex flex-col max-w-6xl mx-auto min-h-screen">
      <div className="grid gap-4 mx-auto max-w-5xl sm:px-6 lg:px-8">
        <div className="space-y-2">
          <div className="flex flex-row mt-10 items-baseline-">
            <h1 className="text-3xl font-bold tracking-tight">{post?.title}</h1>
          </div>
          <Link
            to={`/search?category=${post && post.categoryId?._id}`}
            className="text-sm text-green-400 cursor-pointer mt-5 mb-1"
          >
            <>{post && post.categoryId?.name}</>
          </Link>
          <div className="mt-2 ">
            {userId ? (
              postExists ? (
                <Button onClick={() => addToCart1(post)}>
                  {t('already-added')}
                </Button>
              ) : (
                <Button
                  onClick={() => addToCart1(post)}
                  type="primary"
                  icon={FaRegHeart}
                >
                  {t('add-to-wishlist')}
                </Button>
              )
            ) : (
              <Button className="mt-5 mb-5" onClick={() => redirect()}>
                {t('login-to-view-contact')}
              </Button>
            )}
          </div>
          <div className="grid gap-2">
            <Typography value="In Stock" isRequired={false} />

            <div className="flex items-center gap-2">
              <div className="text-2xl font-bold">{post?.quantity}</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {post?.quantityType} available
              </div>
            </div>
          </div>
        </div>
        <ImagePreviewGroup images={post?.imageUrl} />

        <div className="grid gap-2">
          <Typography value="Seller Details" isRequired={false} />

          <div className="flex items-center gap-2">
            <Avatar className="w-10 h-10 border">
              <Image alt="Seller" src={post?.userId?.imageUrl} />
            </Avatar>
            <div className="grid gap-0.5">
              <div className="font-medium">{post?.userId?.first_name}</div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                {post?.userId?.last_name}
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-2">
          <div className="flex items-center justify-between">
            <span className="text-gray-500 dark:text-gray-400">In Stock</span>
            <span className="font-medium text-gray-900 dark:text-gray-100">
              {post?.quantityType}
            </span>
          </div>
          <Progress percent={50} />
        </div>
        <div className="prose max-w-none dark:prose-invert">
          <p className="text-xl mb-1">About the {post?.title}</p>
          <p className="text-sm">{htmlReactParser(post && post?.content)}</p>
        </div>
        <div className="flex flex-col justify-center items-center mb-5">
          <h1 className="text-xl mt-5">{t('recommended-posts')}</h1>
          <div className="flex flex-wrap gap-5 mt-5 justify-center">
            {recentPosts &&
              recentPosts.map((post) => (
                <PostCard key={post._id} post={post} />
              ))}
          </div>
        </div>

        {publishError && <Message successMessage={publishError} type="error" />}
        {error && <Message successMessage={error} type="error" />}
        {successMessage && (
          <Message successMessage={successMessage} type="success" />
        )}
      </div>
    </main>
  );
}
