import {
  TextInput,
  Button,
  Message,
  Select,
  Skeleton,
} from '../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PostCard from '../../components/PostCard.tsx';
import { useTranslation } from 'react-i18next';
import SORTLIST from '../../utils/constants/Sortlist.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import { ApiUtils } from '../../utils/api.tsx';

export default function Search() {
  const { t } = useTranslation();
  const [sidebarData, setSidebarData] = useState({
    searchTerm: '',
    sort: 'desc',
    category: '',
  });

  // console.log(sidebarData);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const searchTermFromUrl = urlParams.get('searchTerm');
    const sortFromUrl = urlParams.get('sort');
    const categoryFromUrl = urlParams.get('category');
    if (searchTermFromUrl || sortFromUrl || categoryFromUrl) {
      setSidebarData({
        ...sidebarData,
        searchTerm: searchTermFromUrl,
        sort: sortFromUrl,
        category: categoryFromUrl,
      });
    }

    const fetchPosts = async (latitude, longitude) => {
      setLoading(true);
      const searchQuery = urlParams.toString();
      const URL = `${PATHS.SEARCH.GET}?${searchQuery}&latitude=${latitude}&longitude=${longitude}&maxDistance=300`;
      const data = await ApiUtils(URL);

      if (data.status !== 200) {
        setLoading(false);
        return;
      }
      setPosts(data.posts);
      setLoading(false);
      if (data.posts.length === 9) {
        setShowMore(true);
      } else {
        setShowMore(false);
      }
    };

    setLoading(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            fetchPosts(latitude, longitude);
          } catch (error) {
            console.error('Error fetching location:', error);
          }
        },
        (error) => {
          console.error('Error getting user location:', error);
        },
      );
    }

    const fetchCat = async () => {
      const URL = PATHS.CATEGORIES.GET;
      const data = await ApiUtils(URL);
      console.log('datacatlist=======', data);
      if (data.status !== 200) {
        console.log(data.message);
        setPublishError(data.message);
        return;
      } else {
        setPublishError(null);
        setCategoryList(data.response);
      }
    };

    fetchCat();
  }, [location.search]);

  const handleChange = (e) => {
    if (e.target.id === 'searchTerm') {
      setSidebarData({ ...sidebarData, searchTerm: e.target.value });
    }
    if (e.target.id === 'sort') {
      const order = e.target.value || 'desc';
      setSidebarData({ ...sidebarData, sort: order });
    }
    if (e.target.id === 'category') {
      const category = e.target.value || '';
      setSidebarData({ ...sidebarData, category });
    }
  };

  const updateUrlParams = () => {
    const urlParams = new URLSearchParams(location.search);

    if (sidebarData.searchTerm) {
      urlParams.set('searchTerm', sidebarData.searchTerm);
    }

    if (sidebarData.sort) {
      urlParams.set('sort', sidebarData.sort);
    }

    if (sidebarData.category) {
      urlParams.set('category', sidebarData.category);
    }

    return urlParams.toString();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchQuery = updateUrlParams();
    navigate(`/search?${searchQuery}`);
  };

  const handleReset = () => {
    setSidebarData(() => ({
      searchTerm: '',
      sort: 'desc',
      category: '',
    }));
    const searchQuery = updateUrlParams();
    navigate(`/search?${searchQuery}`);
  };

  const handleShowMore = async () => {
    const numberOfPosts = posts.length;
    const startIndex = numberOfPosts;
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('startIndex', startIndex);
    const searchQuery = urlParams.toString();
    const URL = PATHS.SEARCH.GET;
    const data = await ApiUtils(`${URL}?${searchQuery}`);

    if (data.status !== 200) {
      return;
    }
    setPosts([...posts, ...data.posts]);
    if (data.posts.length === 9) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      <div className="p-7 border-b md:border-r md:min-h-screen border-gray-500">
        <form className="flex flex-col gap-8" onSubmit={handleSubmit}>
          <div className="flex items-center justify-end gap-2">
            <label className="whitespace-nowrap font-semibold">
              {t('search-term')}
            </label>
            <TextInput
              placeholder="Search..."
              id="searchTerm"
              type="text"
              value={sidebarData.searchTerm}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center justify-end gap-2">
            <label className="font-semibold">Sort:</label>
            <Select
              onChange={(e) => setSidebarData({ ...sidebarData, sort: e })}
              selectedValue={sidebarData.sort}
              options={SORTLIST}
              placeholder={t('select-a-sort-option')}
              className={'h-10'}
              label={'name'}
              id={'_id'}
            />
          </div>
          <div className="flex items-center justify-end gap-2">
            <label className="font-semibold">Category:</label>
            <Select
              className={'h-10'}
              onChange={(e) => setSidebarData({ ...sidebarData, category: e })}
              options={categoryList}
              placeholder={t('select-a-category')}
              label={'name'}
              id={'_id'}
            />
          </div>
          <Button type="primary" htmlType="submit">
            {t('apply-filters')}
          </Button>
          <Button onClick={handleReset} outline gradientDuoTone="purpleToBlue">
            {t('clear-filters')}
          </Button>
        </form>
      </div>
      <div className="w-full">
        <h1 className="text-3xl font-semibold sm:border-b border-gray-500 p-3 mt-5 ">
          {t('posts-results')}
        </h1>
        <div className="p-7 flex flex-wrap gap-4">
          {!loading && posts.length === 0 && (
            <p className="text-xl text-gray-500">{t('no-posts-found')}</p>
          )}
          {loading && <Skeleton active={true} />}
          {!loading &&
            posts &&
            posts.map((post) => <PostCard key={post._id} post={post} />)}
          {showMore && (
            <button
              onClick={handleShowMore}
              className="text-teal-500 text-lg hover:underline p-7 w-full"
            >
              {t('show-more')}
            </button>
          )}
          {publishError && (
            <Message successMessage={publishError} type="success" />
          )}
        </div>
      </div>
    </div>
  );
}
