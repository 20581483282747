const commonTitle = (key: string, href: string, text: string) => {
  return (
    <a key={key} href={href}>
      {text}
    </a>
  );
};

export const BREADCRUMBPATHS = (t: (key: string) => string) => ({
  'create-user': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('users'), '/users', t('users')),
    },
    { title: t('createaUser') },
  ],
  'create-post': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('posts'), '/posts', t('posts')),
    },
    { title: t('create-a-post') },
  ],
  'create-category': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('categories'), '/categories', t('categories')),
    },
    { title: t('create-categories') },
  ],
  'create-subscriptions': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(
        t('subscriptions'),
        '/subscriptions',
        t('subscriptions'),
      ),
    },
    { title: t('create-subscriptions') },
  ],
  'create-roles': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('roles'), '/roles', t('roles')),
    },
    { title: t('createRole') },
  ],
  'update-roles': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('roles'), '/roles', t('roles')),
    },
    { title: t('updateRoles') },
  ],
  'update-users': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('users'), '/users', t('users')),
    },
    { title: t('updateaUser') },
  ],
  'update-post': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('posts'), '/posts', t('posts')),
    },
    { title: t('update-post') },
  ],
  'update-categories': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(t('categories'), '/categories', t('categories')),
    },
    { title: t('update-categories') },
  ],
  'update-subscriptions': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    {
      title: commonTitle(
        t('subscriptions'),
        '/subscriptions',
        t('subscriptions'),
      ),
    },
    { title: t('update-subscriptions') },
  ],
  'terms-and-conditions': [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('Terms') },
  ],

  dash: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('dashboard') },
  ],
  about: [{ title: t('about'), link: '/' }],
  users: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('users') },
  ],
  profile: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('profile') },
  ],
  posts: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('posts') },
  ],
  categories: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('categories') },
  ],
  roles: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('roles') },
  ],
  orders: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('my-subscriptions') },
  ],
  subscriptions: [
    {
      title: commonTitle(t('home'), '/', t('home')),
    },
    { title: t('subscriptions') },
  ],
});
