import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ImagePreviewGroupScroll,
  Button,
} from '../utils/antd-components/index';
import htmlReactParser from 'html-react-parser';
import React from 'react';

interface PostCardProps {
  post: {
    _id: string;
    title: string;
    content: string;
    imageUrl: string[];
    price?: number;
  };
}

const PostCard: React.FC<PostCardProps> = ({ post }) => {
  const { t } = useTranslation();
  const { _id, title, content, imageUrl } = post;

  return (
    <div className="group relative w-full border border-teal-500 hover:border-2 h-[400px] overflow-hidden rounded-lg sm:w-[300px] transition-all">
      <ImagePreviewGroupScroll images={imageUrl} alt="product image" />
      <Link to={`/post/${_id}`}>
        <div className="p-3 flex flex-col gap-2">
          <p className="text-lg font-semibold line-clamp-2">{title}</p>
          <div className="text-gray-500 text-xs sm:text-sm">
            {htmlReactParser(content)}
          </div>
          <div className="z-10 group-hover:bottom-0 absolute bottom-[-200px] left-0 right-0 hover:text-white transition-all duration-300 text-center py-2 rounded-md !rounded-tl-none m-2">
            <Button className="w-full">{t('view-post')}</Button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PostCard;
