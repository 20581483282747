import { Flex, Progress } from 'antd';
import { FC } from 'react';

// Define a TypeScript interface for the props
interface CommonProgressProps {
  percent: number;
  status?: 'success' | 'exception' | 'normal' | 'active';
  showInfo?: boolean;
  cancelText?: string;
}

export const CommonProgress: FC<CommonProgressProps> = ({
  percent,
  status,
  showInfo = false,
}) => (
  <Flex gap="small" vertical>
    <Progress percent={percent} status={status} showInfo={showInfo} />
  </Flex>
);

export default CommonProgress;
