import {
  TextInput,
  Button,
  Modal,
  Message,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  updateStart,
  updateFailure,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  signoutSuccess,
  updateSuccess,
  signInStop,
} from '../../../redux/user/userSlice.js';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils, clearAuthToken } from '../../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { doSignOut } from '../../../utils/supabase/auth.ts';
import DefaultImage from '../../../assets/images/default-user.png';

export default function DashProfile() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const filePickerRef = useRef();
  const dispatch = useDispatch();
  const { error, currentUser, loading } = useSelector((state) => state.user);
  const userId = authUser?._id;
  const userDetails = currentUser;
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [imageFileUploadProgress, setImageUploadProgress] = useState(null);
  const [imageFileUploadError, setImageUploadError] = useState(null);
  const [imageFileUploading, setImageFileUploading] = useState(false);
  const [updateUserSuccess, setUpdateUserSuccess] = useState(null);
  const [updateUserError, setUpdateUserError] = useState(null);
  const [showCommonModal, setShowCommonModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [publishError, setPublishError] = useState(null);

  useEffect(() => {
    try {
      const fetchUserDetails = async () => {
        const URL = PATHS.USERS.INDIVIDUAL;
        const data = await ApiUtils(URL + `?_id=${userId}&status=all`);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.users[0]);
        }
      };

      fetchUserDetails();
    } catch (error) {
      console.log(error.message);
    }
  }, [userId]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImageFileUrl(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (imageFile) {
      uploadImage();
    }
  }, [imageFile]);

  const uploadImage = async () => {
    try {
      if (!imageFile) {
        setImageUploadError(t('please-select-an-image'));
        return;
      }
      setImageUploadError(null);
      setImageFileUploading(true);
      setImageUploadProgress(20);
      setImageUploadProgress(30);
      setImageUploadProgress(50);
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('folderName', 'user');
      const URL = PATHS.IMAGE.ADD;
      const data = await ApiUtils(
        URL,
        formData,
        METHOD_TYPES.POST,
        '',
        'multipart/form-data',
      );

      if (data.status === 200) {
        setImageUploadProgress(60);
        setImageUploadProgress(70);
        setFormData((prevFormData) => ({
          ...prevFormData,
          imageUrl: data.response[0].location,
        }));
        setImageFileUrl(data.response[0].location);

        setImageUploadProgress(null);
        setImageUploadError(null);
        setImageFileUploading(false);
      } else {
        throw new Error(t('image-upload-failed'));
      }
    } catch (error) {
      setImageFileUrl(null);
      setImageFileUploading(false);
      setImageUploadError(t('image-upload-failed'));
      setImageUploadProgress(null);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    dispatch(signInStop());
    dispatch(updateFailure(null));
    setUpdateUserError(null);
    setUpdateUserSuccess(null);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdateUserError(null);
    setUpdateUserSuccess(null);
    if (Object.keys(formData).length === 0) {
      setUpdateUserError(t('no-changes-made'));
      return;
    }
    if (imageFileUploading) {
      setUpdateUserError(t('please-wait-for-image-to-upload'));
      return;
    }
    try {
      dispatch(updateStart());
      const URL = `${PATHS.USERS.UPDATE}/${userId}`;
      const data = await ApiUtils(URL, formData, METHOD_TYPES.PUT);

      if (data.status !== 200) {
        dispatch(updateFailure(data.message));
        setUpdateUserError(data.message);
      } else if (data.status === 200) {
        // console.log('data in user update====', data.response);
        dispatch(updateSuccess(data.response));
        setUpdateUserSuccess(t('users-profile-updated-successfully'));
      }
    } catch (error) {
      console.log('err in user update====', error);
      //dispatch(updateFailure(error.message));
      setUpdateUserError(error.message);
    }
  };

  const handleDeleteUser = async () => {
    setShowCommonModal(false);
    try {
      dispatch(deleteUserStart());
      let payLoad = {
        _id: userId,
      };
      const URL = PATHS.USERS.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status !== 200) {
        dispatch(deleteUserFailure(data.message));
      } else {
        dispatch(deleteUserSuccess(data));
      }
    } catch (error) {
      dispatch(deleteUserFailure(error.message));
    }
  };

  const handleSignout = async () => {
    try {
      await doSignOut();
      signOut();
      clearAuthToken();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log('formdata===', formData);
  return (
    <div className="max-w-lg mx-auto p-3 w-full">
      <h1 className="my-7 text-center font-semibold text-3xl">
        {t('profile')}
      </h1>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          ref={filePickerRef}
          hidden
        />
        <div
          className="relative w-32 h-32 self-center cursor-pointer shadow-md overflow-hidden rounded-full"
          onClick={() => filePickerRef.current.click()}
        >
          {imageFileUploadProgress && (
            <CircularProgressbar
              value={imageFileUploadProgress || 0}
              text={`${imageFileUploadProgress}%`}
              strokeWidth={5}
              styles={{
                root: {
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
                path: {
                  stroke: `rgba(62, 152, 199, ${
                    imageFileUploadProgress / 100
                  })`,
                },
              }}
            />
          )}
          {/* <img
            src={
              imageFileUrl || formData.imageUrl
                ? formData.imageUrl
                : DEFAULTUSER
            } */}
          <img
            src={
              imageFileUrl
                ? imageFileUrl
                : userDetails?.imageUrl
                  ? userDetails?.imageUrl
                  : DefaultImage
            }
            alt="users"
            className={`rounded-full w-full h-full object-cover border-8 border-[lightgray] ${
              imageFileUploadProgress &&
              imageFileUploadProgress < 100 &&
              'opacity-60'
            }`}
          />
        </div>
        {imageFileUploadError && (
          <Message successMessage={imageFileUploadError} type="error" />
        )}

        <TextInput
          type="text"
          id="username"
          placeholder="username"
          value={formData?.username}
          disabled={true}
          onChange={handleChange}
        />
        <TextInput
          type="email"
          id="email"
          placeholder="email"
          disabled={true}
          value={formData?.email}
          onChange={handleChange}
        />
        <TextInput
          type="text"
          id="first_name"
          placeholder={t('john')}
          value={formData?.first_name}
          onChange={handleChange}
        />
        <TextInput
          type="text"
          id="last_name"
          placeholder={t('doe')}
          value={formData?.last_name}
          onChange={handleChange}
        />
        <Button
          type="primary"
          htmlType="submit"
          disabled={loading || imageFileUploading}
        >
          {loading ? 'Loading...' : t('update')}
        </Button>
        {userDetails?.roleId?.type.includes('admin') && (
          <Link to={'/create-post'}>
            <Button
              type="button"
              gradientDuoTone="greenToBlue"
              className="w-full"
            >
              {t('create-a-post')}
            </Button>
          </Link>
        )}
      </form>
      <div className="text-red-500 flex justify-between mt-5">
        {userDetails?.roleId?.type.includes('admin') && (
          <span
            onClick={() => setShowCommonModal(true)}
            className="cursor-pointer"
          >
            {t('delete-account')}
          </span>
        )}
        <span onClick={handleSignout} className="cursor-pointer">
          <Link to="/">{t('sign-out')}</Link>
        </span>
      </div>
      {updateUserSuccess && (
        <Message successMessage={updateUserSuccess} type="success" />
      )}
      {updateUserError && (
        <Message successMessage={updateUserError} type="error" />
      )}
      {error && <Message successMessage={error} type="error" />}
      {publishError && <Message successMessage={publishError} type="error" />}

      <Modal
        title={'Delete User'}
        visible={showCommonModal}
        onOk={handleDeleteUser}
        onCancel={() => setShowCommonModal(false)}
        okText={t('yes-im-sure')}
        cancelText={t('no-cancel')}
      >
        {t('are-you-sure-you-want-to-delete-your-account')}
      </Modal>
    </div>
  );
}
