import { FC } from 'react';
import { Image } from 'antd';
import DefaultImage from '../../assets/images/default-user.png';

interface CustomImageProps {
  key?: string;
  className?: string;
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
}

export const CustomImage: FC<CustomImageProps> = ({
  key,
  className = 'w-10 h-10 rounded-full object-cover',
  src = DefaultImage,
  alt = '',
  height = 60,
  width = 60,
}) => {
  return (
    <Image
      key={key}
      className={className}
      src={src}
      alt={alt}
      height={height}
      width={width}
    />
  );
};
