import { useTranslation } from 'react-i18next';

export default function About() {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="max-w-6xl mx-auto p-3 text-center">
        <div>
          <h1 className="text-3xl font font-semibold text-center my-7">
            {t('PricayPolicy')}
          </h1>
          <div className="text-md text-gray-500 flex flex-col gap-6 mb-5">
            <p>{t('policy1')}</p>

            <p>{t('policy2')}</p>

            <p>{t('policy3')}</p>
            <p>{t('policy4')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
