import { Dropdown, Layout } from 'antd';
import {
  Avatar,
  Button,
  SearchInput,
  Badge,
} from '../utils/antd-components/index.tsx';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaMoon, FaSun, FaRegHeart } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { toggleTheme } from '../redux/theme/themeSlice.js';
import { signoutSuccess } from '../redux/user/userSlice.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { clearAuthToken } from '../utils/api.tsx';
import { doSignOut } from '../utils/supabase/auth.ts';
import LOGO from '../assets/images/swirlz-logo.jpg';
import { ApiUtils } from '../utils/api.tsx';
import PATHS from '../utils/constants/Paths.tsx';
import { addcart, addcartCount } from '../redux/cart/cartSlice.js';

export default function Header() {
  const { Header } = Layout;
  const { t } = useTranslation();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useAuthUser();
  const userId = authUser?._id;
  const userDetails = authUser?.userDetails;
  const { theme } = useSelector((state) => state.theme);
  const { cartCount } = useSelector((state) => state.cart);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const searchTermFromUrl = urlParams.get('searchTerm');
    if (searchTermFromUrl) {
      setSearchTerm(searchTermFromUrl);
    }
    const fetchPost = async () => {
      try {
        const URL = `${PATHS.WISHLIST.LIST}?userId=${userId}`;
        const data = await ApiUtils(URL);
        if (data.status === 200) {
          dispatch(addcart(data.response));
          dispatch(addcartCount(data.totalCount));
        }
      } catch (error) {
        console.log('err in header search box', error.message);
      }
    };
    if (userId) {
      fetchPost();
    }
  }, [location.search, userId]);

  const handleSignout = async () => {
    try {
      await doSignOut();
      signOut();
      dispatch(signoutSuccess());
      clearAuthToken();
      navigate('/');
    } catch (error) {
      console.log('err in header search box', error.message);
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('searchTerm', e);
    const searchQuery = urlParams.toString();
    navigate(`/search?${searchQuery}`);
  };
  const currentCartNumber = cartCount;
  // console.log('counts====', cartDetails);
  const items = [
    {
      key: '1',
      label: <Link to="/profile">Profile</Link>,
    },
    {
      key: '2',
      label: (
        <>
          <span className="block text-sm">@{userDetails?.username}</span>
          <span className="block text-sm font-medium truncate">
            {userDetails?.email}
          </span>
        </>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          className="w-12 h-10 hidden sm:inline"
          type="text"
          onClick={() => dispatch(toggleTheme())}
        >
          {theme === 'light' ? <FaSun /> : <FaMoon />}
        </Button>
      ),
    },
    {
      key: '5',
      label: <p onClick={handleSignout}>Sign Out</p>,
    },
  ];
  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  return (
    <Header className="border-b-2 w-full items-center flex flex-row gap-x-3 justify-between  sticky top-0 z-[100]">
      {window.innerWidth > 768 && (
        <div className="flex  justify-start gap-x-3">
          <Link
            to="/"
            className="self-center whitespace-nowrap text-sm sm:text-xl font-semibold dark:text-white"
          >
            <img
              src={LOGO}
              alt="Swirlz Recycle"
              className="h-12 w-14 sm:h-12 sm:w-12"
            />
            {/* <span className="px-2 py-1 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg text-white">
            {t('swirlz')}
          </span>

          {t('recycle')}*/}
          </Link>
        </div>
      )}

      <div className="flex justify-around  items-center gap-x-3">
        <SearchInput
          type="text"
          placeholder="Search..."
          // className="hidden lg:inline"
          value={searchTerm}
          onSearch={handleSubmit}
          onChange={handleSearchChange}
          allowClear={true}
          enterButton
          classNames="items-self"
        />
      </div>
      <div className="flex justify-end gap-x-3">
        <div className="flex gap-4 md:order-2 items-center">
          {userId && window.innerWidth > 768 && (
            <>
              <p className="text-sm">
                <Link to="/">{t('home')}</Link>
              </p>
              <p className="text-sm">
                <Link to="/about">{t('about')}</Link>
              </p>
              <p className="text-sm">
                <Link to="/subscriptions">{t('subscription')}</Link>
              </p>
              {userId && (
                <Link to="/view-wishlist" className="no-underline text-current">
                  <Badge
                    count={currentCartNumber}
                    className="w-10 h-10 hidden sm:inline"
                  >
                    <FaRegHeart size={20} />
                  </Badge>
                </Link>
              )}
            </>
          )}
          <div className="flex gap-2">
            <Button
              className="w-12 h-10 hidden sm:inline"
              type="text"
              onClick={() => dispatch(toggleTheme())}
            >
              {theme === 'light' ? <FaSun /> : <FaMoon />}
            </Button>
          </div>
          {window.innerWidth > 768 &&
            (userId ? (
              <div>
                <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Avatar alt="user" src={userDetails?.imageUrl} rounded />
                  </a>
                </Dropdown>
              </div>
            ) : (
              <Link to="/sign-in">
                <Button type="primary" shape="round">
                  {t('login')}
                </Button>
              </Link>
            ))}

          {window.innerWidth < 768 &&
            (userId ? (
              <div>
                <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Avatar alt="user" src={userDetails?.imageUrl} rounded />
                  </a>
                </Dropdown>
              </div>
            ) : (
              <Link to="/sign-in">
                <Button type="primary" shape="round">
                  {t('login')}
                </Button>
              </Link>
            ))}
        </div>
      </div>
    </Header>
  );
}
