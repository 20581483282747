import {
  Select,
  Button,
  Popconfirm,
  Image,
  Message,
  Table,
  Tag,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { STATUSLIST } from '../../../utils/constants/index.ts';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import ScrollToTop from '../../../components/ScrollToTop.tsx';

export default function DashPosts() {
  const { t } = useTranslation();
  const translatedSTATUSLIST = STATUSLIST(t);
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;

  const [pageSize, setPageSize] = useState(10);
  const [userPosts, setUserPosts] = useState([]);
  const [filter, setFilter] = useState(roles?.includes('admin') ? 'all' : 1);
  const [publishError, setPublishError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    <ScrollToTop />;
    console.log('heeloo');
    if (userId) {
      console.log('heeloo1');
      fetchPosts();
    }
  }, [userId, filter]);

  const fetchPosts = async () => {
    try {
      setLoading(true);
      let URL;
      if (roles?.includes('admin')) {
        URL = `${PATHS.ADMIN.POSTS}?status=${filter}`;
      } else {
        URL = `${PATHS.POSTS.LIST}?status=1`;
      }
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUserPosts(data.posts);
        setTotalCount(data.totalCount);
      } else {
        console.log('error in get post', data.message);
        setPublishError(data.message);
      }
      setLoading(false);
    } catch (error) {
      console.log('error in get post', error.message);
      setPublishError(error.message);
      setLoading(false);
    }
  };

  const handleShowMore = async (page, pageSize) => {
    try {
      setLoading(true);
      setPageSize(pageSize);
      setPageCount(page);

      let URL;
      if (roles?.includes('admin')) {
        URL = `${PATHS.ADMIN.POSTS}?status=${filter}&page=${page}`;
      } else {
        URL = `${PATHS.POSTS.LIST}?status=${filter}&page=${page}`;
      }
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUserPosts(data.posts);
      }
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const handleMarkAsApproved = async (id, userId) => {
    try {
      const URL = `${PATHS.POSTS.UPDATE}/${id}?userId=${userId}`;
      const data = await ApiUtils(URL, { status: 1 }, 'PUT');
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        setPublishError(data.message);
        return;
      } else {
        fetchPosts();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
      setPublishError(error.message);
    }
  };

  const handleDeletePost = async (id, userId) => {
    try {
      setSuccessMessage(null);
      setPublishError(null);
      const payLoad = { _id: id, userId: userId };
      const data = await ApiUtils(
        `/swirlz/posts/delete-posts`,
        payLoad,
        'DELETE',
      );

      if (data.status !== 200) {
        console.log('error in delete post====', data.message);
        setPublishError(data.message);
      } else {
        setUserPosts((prev) => prev.filter((post) => post._id !== id));
        setSuccessMessage(data.message);
      }
    } catch (error) {
      console.log(error.message);
      console.log('error in delete post1===', error.message);
      setPublishError(error.message);
    }
  };

  const columns = [
    {
      title: t('date-updated'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: t('post-image'),
      dataIndex: 'imageUrl',
      key: 'imageUrl',
      render: (text, record) => (
        <Link to={`/post/${record._id}`}>
          <Image src={text.length > 0 && text[0]} alt={record.title} />
        </Link>
      ),
    },
    {
      title: t('post-title'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <Link
          to={`/post/${record._id}`}
          className="font-medium text-gray dark:white"
        >
          {text}
        </Link>
      ),
    },
    {
      title: t('category'),
      dataIndex: ['categoryId', 'name'],
      key: 'categoryId',
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) =>
        roles?.includes('admin') ? (
          <>
            {status === 2 ? (
              <Popconfirm
                title={t('are-you-sure-you-want-to-approve-this-post')}
                onConfirm={() =>
                  handleMarkAsApproved(record._id, record.userId)
                }
                okText={t('yes')}
                cancelText={t('no')}
              >
                <a
                  href="#"
                  className="font-medium text-red-500 hover:underline cursor-pointer"
                >
                  {t('mark-as-approved')}
                </a>
              </Popconfirm>
            ) : status === 1 ? (
              <Tag active="1">{t('active')}</Tag>
            ) : status === 2 ? (
              <Tag active="2">{t('inActive')}</Tag>
            ) : (
              <Tag active="3">{t('deleted')}</Tag>
            )}
          </>
        ) : (
          <>{status === 1 ? t('active') : t('waiting-for-approval')}</>
        ),
    },
    {
      title: t('edit'),
      key: 'edit',
      render: (text, record) => (
        <Link
          className="text-teal-500 hover:underline"
          to={`/update-post/${record._id}`}
        >
          {t('edit')}
        </Link>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (text, record) => (
        <Popconfirm
          title={t('are-you-sure-delete-this-post')}
          onConfirm={() => handleDeletePost(record._id, record.userId)}
          okText={t('yes')}
          cancelText={t('no')}
        >
          <a
            href="#"
            className="font-medium text-red-500 hover:underline cursor-pointer"
          >
            {t('delete')}
          </a>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <div className="w-full flex flex-row justify-between my-1 mb-3">
        <Link to={'/create-post'}>
          <Button
            type="button"
            gradientDuoTone="greenToBlue"
            className="w-full"
          >
            {t('create-a-post')}
          </Button>
        </Link>
        {roles?.includes('admin') && (
          <Select
            required={true}
            onChange={(e) => setFilter(e)}
            options={translatedSTATUSLIST}
            placeholder={t('select')}
            label="name"
            className={'w-1/4'}
          />
        )}
      </div>
      <Table
        dataSource={userPosts}
        columns={columns}
        rowKey="_id"
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: pageCount,
          onChange: (page, pageSize) => {
            handleShowMore(page, pageSize);
          },
        }}
        loading={loading}
      />

      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
