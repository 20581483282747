import { FC, ReactNode } from 'react';
import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';

interface CustomUploadProps
  extends Omit<UploadProps, 'onChange' | 'beforeUpload' | 'fileList'> {
  accept?: string;
  onChange?: (info: any) => void;
  beforeUpload?: (file: RcFile, fileList: RcFile[]) => boolean | Promise<void>;
  customRequest?: (options: any) => void;
  fileList?: UploadFile<any>[];
  children?: ReactNode;
}

export const CustomUpload: FC<CustomUploadProps> = ({
  type,
  className,
  accept,
  onChange,
  fileList,
  customRequest,
  multiple = false,
  beforeUpload,
  children,
}) => {
  return (
    <Upload
      type={type}
      className={className}
      accept={accept}
      onChange={onChange}
      fileList={fileList}
      multiple={multiple}
      customRequest={customRequest}
      beforeUpload={beforeUpload}
    >
      {children}
    </Upload>
  );
};
