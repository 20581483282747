import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ConfigProvider, theme as antdTheme } from 'antd';
import CSSUtility from '../utils/CSSUtility';

interface ThemeProviderProps {
  children: ReactNode;
}

interface RootState {
  theme: {
    theme: 'dark' | 'light';
  };
}

const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const { theme } = useSelector((state: RootState) => state.theme);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary:
            theme === 'dark'
              ? CSSUtility.darkcolor.primary
              : CSSUtility.color.primary,
        },
        components: {
          Menu: {
            itemSelectedColor: '#42ea4f',
          },
          Pagination: {
            colorPrimary: '#fbce46',
          },
        },
        algorithm:
          theme === 'dark'
            ? antdTheme.darkAlgorithm
            : antdTheme.defaultAlgorithm,
      }}
    >
      <div className={`${theme}`}>
        <div className="ant-menu ant-layout-header ant-layout bg-slate-900 text-gray-700 dark:text-gray-200 dark:bg-[rgb(20,20,20)] min-h-screen">
          {children}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default ThemeProvider;
