const PATHS = {
  ADMIN: {
    ADD: '/adminom/deities/add-deities',
    POSTS: '/api/admin/getposts',
  },
  COMMENTS: {
    GET: '/swirlz/comments/get-comments',
    UPDATE: '/swirlz/comments/update-comments',
    ADD: '/swirlz/comments/add-comments',
    LIKE_COM: '/swirlz/comments/like-comments',
    DELETE: '`/swirlz/comments/delete-comments',
  },
  SEARCH: {
    GET: '/swirlz/search/get',
  },
  PRICING: {
    GET: '/api/pricing/get-pricing',
    DELETE: '/api/pricing/delete-pricing',
    UPDATE: '/api/pricing/update-pricing/',
    ADD: '/api/pricing/add-pricing/',
  },
  AUTH: {
    REGISTER: '/swirlz/auth/register',
    SUPABASEREGISTER: '/swirlz/auth/supabase-register',
    VER_EMAIL: '/swirlz/auth/verify-email',
    RES_CODE: '/swirlz/auth/resend-code',
    LOGIN: '/swirlz/auth/login',
  },
  WISHLIST: {
    ADD: '/swirlz/wishlist/add-wishlist',
    LIST: '/swirlz/wishlist/list-wishlist',
    DELETE: '/swirlz/wishlist/delete-wishlist',
  },
  PROFILE: {
    ADD: '',
    GET: '',
    DELETE: '',
    UPDATE: '',
  },
  POSTS: {
    RECPOST: '/swirlz/posts/get-recent-post',
    GET: '/swirlz/posts/get-posts',
    LIST: '/swirlz/posts/list-posts',
    INDIVIDUAL: '/swirlz/posts/individual-post',
    UPDATE: '/swirlz/posts/update-posts',
    AUTH: '/api/v1/cognito/login',
    ADD: '/swirlz/posts/add-posts/',
    DELETE: '',
    POST: '/swirlz/posts/add-posts/',
  },
  CATEGORIES: {
    ADD: '/api/categories/add-categories',
    GET: '/swirlz/categories/get-categories',
    DELETE: '/api/categories/delete-categories',
    UPDATE: '/api/categories/update-categories',
  },
  IMAGE: {
    POST: '/swirlz/images/add-images/',
    ADD: ' /swirlz/images/add-images/ ',
  },
  USERS: {
    INDIVIDUAL: '/swirlz/user/get-user-individual',
    CREATE: '/api/user/create-user',
    GET: '/api/user/get-user',
    DELETE: '/api/user/delete-user',
    UPDATE: '/swirlz/user/update-user',
    ADMINUPDATE: '/api/user/update-user',
  },
  ROLES: {
    ADD: '/api/roles/add-roles/',
    GET: '/api/roles/get-roles',
    DELETE: '/api/roles/delete-roles',
    UPDATE: '/api/roles/update-roles',
  },
  SUBSCRIPTION: {
    ADD: '/api/subscriptions/add-subscriptions/',
    GET: '/swirlz/subscriptions/get-subscriptions',
    DELETE: '/api/subscriptions/delete-subscriptions',
    POST: ' ',
    UPDATE: '/api/subscriptions/update-subscriptions',
  },
  SETTINGS: {
    GET: '  ',
    DELETE: ' ',
    POST: '/swirlz/auth/changepassword',
    UPDATE: ' ',
  },
  PAYMENTS: {
    GET: '/swirlz/payments/get-transaction',
    DELETE: ' ',
    CREATEINTENT: '/swirlz/payments/create-payment-intent',
    CREATETRANSACTION: '/swirlz/payments/create-transaction',
    UPDATE: ' ',
  },
};

export default PATHS;
