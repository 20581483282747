import { FC } from 'react';
import { Table } from 'antd';
import { TableProps, ColumnType } from 'antd/lib/table';

interface DataType {
  _id: string;
  className: string;
  scroll?: boolean;
  bordered?: boolean;
  rowKey?: string;
  rowHoverable?: boolean;
  [key: string]: any;
}

interface ExtendedTableProps<RecordType> extends TableProps<RecordType> {
  scrollToFirstRowOnChange?: boolean;
}

interface CommonAntdTableProps
  extends Omit<ExtendedTableProps<DataType>, 'columns' | 'dataSource'> {
  dataSource: DataType[];
  columns: ColumnType<DataType>[];
}

export const CommonAntdTable: FC<CommonAntdTableProps> = ({
  dataSource,
  columns,
  pagination,
  loading,
  rowKey = '_id',
  bordered = false,
  className = 'shadow-md overflow-x-auto',
  rowHoverable = true,
  scrollToFirstRowOnChange = true,
  onChange,
}) => {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
      loading={loading}
      rowKey={rowKey}
      className={className}
      rowHoverable={rowHoverable}
      bordered={bordered}
      scroll={{
        x: true,
        scrollToFirstRowOnChange: scrollToFirstRowOnChange,
      }}
    />
  );
};
