import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PostCard from '../../components/PostCard.tsx';
import { ApiUtils } from '../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Skeleton } from '../../utils/antd-components/index.tsx';

export default function Home() {
  const authUser = useAuthUser();
  const userId = authUser ? authUser._id : null;
  const { t } = useTranslation();
  const roles = authUser ? authUser.rolesType : [];

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      let queryParams = '';
      if (userId) {
        queryParams = `?user=${userId}`;
      }
      const URL = PATHS.POSTS.RECPOST;
      const data = await ApiUtils(URL, `${queryParams}`);

      setPosts(data.posts);
      setLoading(false);
    };
    fetchPosts();
  }, [userId]);

  if (loading) {
    return (
      <div className="flex flex-col gap-6 p-28 px-3 max-w-6xl mx-auto ">
        <Skeleton active={true} />;
      </div>
    );
  }

  return (
    <div>
      {roles.includes('admin') && (
        <div className="flex flex-col gap-6 p-28 px-3 max-w-6xl mx-auto ">
          <h1 className="text-3xl font-bold lg:text-6xl text-center">
            {t('welcome-to-my-admin-portal')}
          </h1>
          <p className="text-gray-500 text-xs sm:text-sm text-center">
            {t(
              'here-youll-find-a-variety-of-articles-and-tutorials-on-topics-such-as-web-development-software-engineering-and-programming-languages',
            )}
          </p>
          <Link
            to="/search"
            className="text-xs sm:text-xl text-teal-500 font-bold hover:underline text-center"
          >
            {t('view-all-posts')}
          </Link>
        </div>
      )}

      {/* <div className='p-3 bg-amber-100 dark:bg-slate-700'>
        <CallToAction />
      </div> */}

      <div className="max-w-7xl mx-auto p-3 flex flex-col gap-8 py-7">
        {posts && posts.length > 0 && (
          <div className="flex flex-col gap-6">
            <h2 className="text-2xl font-semibold text-center">
              {t('recent-posts')}
            </h2>
            <div className="flex flex-wrap mx-auto gap-4">
              {posts.map((post) => (
                <PostCard key={post._id} post={post} />
              ))}
            </div>
            <Link
              to={'/search'}
              className="text-lg text-teal-500 hover:underline text-center"
            >
              {t('view-all-posts')}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}
