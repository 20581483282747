import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/* This Functions is to convert the timestring to local 12hr format */
export const populate12HrFormat = (timeString: string): string => {
  const date = new Date(timeString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
  return formattedTime;
};

/* This Functions is to calculate the time difference in hours */
export const calculateTimeDifferenceInHours = (
  startTimeStr: string,
  endTimeStr: string,
): string => {
  if (!endTimeStr) {
    return '0 hr';
  }
  const startTime = new Date(startTimeStr);
  const endTime = new Date(endTimeStr);

  const timeDiffInMilliseconds = Math.abs(
    endTime.getTime() - startTime.getTime(),
  );
  const hours = Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.ceil(
    (timeDiffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );

  if (minutes <= 15) {
    return `${hours} hr`;
  } else if (minutes <= 45) {
    return `${hours} hr 30 mins`;
  } else {
    return `${hours + 1} hr`;
  }
};

interface TitleAndBackComponentProps {
  title: string;
  goBackText: React.ReactNode;
}

/* This function is were we declare the heading & backbutton in create & update forms */
export const TitleAndBackComponent = ({
  title,
  goBackText,
}: TitleAndBackComponentProps) => {
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-row gap-4 sm:flex-row justify-between items-center">
      <div></div>
      <h1 className="text-center text-3xl my-7 font-semibold">{title}</h1>
      <p
        onClick={goBackHandler}
        className="text-blue-500 underline cursor-pointer hover:underline"
      >
        {goBackText}
      </p>
    </div>
  );
};

/* This function is used to scroll to the top of the page */
export const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};
