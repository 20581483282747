import { useTranslation } from 'react-i18next';
import TC from '../../assets/images/tc.png';

export default function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen  items-center  justify-center p-6">
      <div className="max-w-3xl mx-auto shadow-md rounded-lg p-8 ">
        <h1 className="text-3xl font-bold mb-10 text-center flex justify-center items-center">
          <img
            src={TC}
            alt={t('Terms')}
            className="block mr-2"
            style={{ width: '8%' }}
          />
          {t('Terms')}
        </h1>

        <hr className="border-t-1 border-gray-300 my-4 mx-auto w-3/4" />

        <div className="mt-14 ">
          <h2 className="text-2xl font-semibold mb-4">{t('Introduction')}</h2>
          <p style={{ textAlign: 'justify' }}>{t('welcomeToSwirlz')}</p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mt-10 mb-6 text-center">
            {t('Use of Swirlz')}
          </h2>

          <h3 className="text-xl font-semibold mb-4">{t('Eligibility')}</h3>
          <p className="mb-6" style={{ textAlign: 'justify' }}>
            <ul className="list-disc pl-5 mt-2 ">
              <li>{t('eligibilityMessage')}</li>
            </ul>
          </p>

          <h3 className="text-xl font-semibold mb-4">
            {t('Account Registration')}
          </h3>
          <p className="mb-6" style={{ textAlign: 'justify' }}>
            <ul className="list-disc pl-5 mt-2 ">
              <li> {t('accountRegistrationMessage')}</li>
              <li> {t('accountRegistrationMessage2')}</li>
            </ul>
          </p>

          <h3 className="text-xl font-semibold mb-4">
            {t('Prohibited Conduct')}
          </h3>
          <p className="mb-6" style={{ textAlign: 'justify' }}>
            <ul className="list-disc pl-5 mt-2 ">
              <li>{t('prohibitedConductMessage')}</li>
              <li>{t('prohibitedConductMessage2')}</li>
              <li>{t('prohibitedConductMessage3')}</li>
            </ul>
          </p>
        </div>

        <div className="mb-8 mt-10">
          <h2 className="text-2xl font-semibold mb-8 text-center">
            {t('content')}
          </h2>

          <h3 className="text-xl font-semibold mb-2">{t('Your Content')}</h3>
          <p className="mb-6" style={{ textAlign: 'justify' }}>
            {t('contentOwnershipMessage')}
          </p>

          <h3 className="text-xl font-semibold mb-2">{t('Our Content')}</h3>
          <p className="mb-4" style={{ textAlign: 'justify' }}>
            {t('intellectualPropertyMessage')}
          </p>
        </div>

        <div className="mb-6 mt-10">
          <h2 className="text-2xl font-semibold mb-4">{t('Privacy')}</h2>
          <p style={{ textAlign: 'justify' }}>{t('privacyPolicyMessage')}</p>
        </div>

        <div className="mb-6 mt-10">
          <h2 className="text-2xl font-semibold mb-4">{t('Termination')}</h2>
          <p style={{ textAlign: 'justify' }}>{t('terminationMessage')}</p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 mt-10">
            {t('DisclaimerofWarranties')}
          </h2>
          <p style={{ textAlign: 'justify' }}>
            {t('disclaimerOfWarrantiesMessage')}
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 mt-10">
            {t('LimitationofLiability')}
          </h2>
          <p style={{ textAlign: 'justify' }}>
            {t('limitationOfLiabilityMessage')}
          </p>
          <ul className="list-disc pl-5 mt-2 ">
            <li>{t('Youruse')}</li>
            <li>{t('Anyunauthorized')}</li>
            <li>{t('Anybugs')}</li>
          </ul>
        </div>

        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 mt-10">
            {t('Changes to These Terms')}
          </h2>
          <p style={{ textAlign: 'justify' }}>{t('changesToTermsMessage')}</p>
        </div>

        <div>
          <h2 className="text-2xl font-semibold mb-4 mt-10">
            {t('Contact Us')}
          </h2>
          <p>{t('contactUsMessage')}</p>
        </div>
      </div>
    </div>
  );
}
