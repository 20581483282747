import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';

export default function OnlyAdminPrivateRoute() {
  // const { currentUser } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const redirectToHome = async () => {
    console.log('button click');
    return navigate('/');
  };
  return (
    <Result
      status="error"
      title="Payment Failed"
      subTitle="Payment failed pls try again later!"
      extra={
        <Button
          type="primary"
          className="ml-1/2 flex "
          onClick={() => redirectToHome()}
        >
          Back Home
        </Button>
      }
    />
  );
}
