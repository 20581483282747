import {
  TextInput,
  Select,
  Button,
  RadioGroup,
  InputNumber,
  Message,
  Typography,
  Tabs,
} from '../../utils/antd-components/index.tsx';
import { Select as SelectAntd } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.tsx';
import {
  TitleAndBackComponent,
  ScrollToTop,
} from '../../utils/reUsable/index.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import { CityList } from '../../utils/constants/Citylist.tsx';
import ImageUpload from '../../utils/reUsable/ImageUpload.tsx';

export default function CreatePost() {
  const { t } = useTranslation();
  const { Option } = SelectAntd;
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [currentLocation, setCurrentLocation] = useState(false);
  const [formData, setFormData] = useState({
    quantity: 1,
    locations: { type: 'Point', coordinates: [] },
  });
  const [categoryList, setCategoryList] = useState(null);
  const [publishError, setPublishError] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    try {
      const fetchPost = async () => {
        const URL = `${PATHS.CATEGORIES.GET}?limit=100`;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setCategoryList(data.response);
        }
      };

      fetchPost();
      <ScrollToTop />;
      // Check if geolocation is supported
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
            // Reverse geocode the coordinates to get the location name
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
              );
              const data = await response.json();
              if (data.address) {
                setCurrentLocation(true);
                setLocationName(
                  data.address.state ||
                    data.address.city ||
                    data.address.county ||
                    'Unknown Location',
                );
              }
            } catch (error) {
              console.error('Error fetching location:', error);
            }
          },
          (error) => {
            console.error('Error getting user location:', error);
          },
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPublishError(null);
    setLoading(true);

    try {
      if (
        !formData.title ||
        !formData.imageUrl ||
        !formData.content ||
        !userLocation ||
        !formData.locations
      ) {
        setPublishError(t('please-fill-all-the-fields'));
        return;
      }

      // Add location coordinates to formData
      formData.locations = {
        type: 'Point',
        coordinates: [userLocation.longitude, userLocation.latitude],
      };
      // console.log('updatedData====', formData);
      const URL = PATHS.POSTS.ADD;
      const data = await ApiUtils(URL, formData, METHOD_TYPES.POST);

      setLoading(false);

      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      }

      if (data.status === 200) {
        setPublishError(null);
        navigate(`/post/${data.posts._id}?status=2`);
      }
    } catch (error) {
      console.log('err in create post', error);
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  // const handlePriceChange = (e) => {
  //   setFormData({ ...formData, price: e });
  // };

  const handleQuantityChange = (e) => {
    setFormData({ ...formData, quantity: e });
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  // const handleRadioChangeType = (e) => {
  //   const statusValue = e.target.value;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     type: statusValue,
  //     price: statusValue === 1 ? 0 : 1,
  //   }));
  // };

  const handleImageChange = (e) => {
    setFormData({ ...formData, imageUrl: e });
  };

  const quantityType = [
    { _id: 'kg', name: 'Kilogram' },
    { _id: 'liter', name: 'Liter' },
    { _id: 'count', name: 'Numbers' },
  ];

  const handleLocationChange = (cityValueString) => {
    // Set selected location
    if (!cityValueString) {
      return;
    }
    const value = JSON.parse(cityValueString);
    // Convert the array to an object with latitude and longitude keys
    const coordinates = {
      latitude: value[0],
      longitude: value[1],
    };
    setUserLocation(coordinates);
    console.log('cityvalue=====', value);

    if (coordinates) {
      // Update formData with selected location coordinates
      setFormData({
        ...formData,
        locations: {
          type: 'Point',
          coordinates: coordinates,
        },
      });
    }
  };

  // console.log('userLocation===', userLocation);
  const items = [
    {
      key: '1',
      label: t('Chooese you location'),
      disabled: currentLocation ? true : false,
      children: (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <div className="w-full flex flex-col gap-2 mt-3">
            <SelectAntd
              showSearch
              style={{ width: 200 }}
              placeholder={t('select-a-city')}
              optionFilterProp="children"
              onChange={handleLocationChange} // Function to handle selection
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CityList.map((city) => (
                <Option key={city._id} value={JSON.stringify(city.value)}>
                  {city._id}
                </Option>
              ))}
            </SelectAntd>
          </div>
        </form>
      ),
    },
    {
      key: '2',
      label: t('Your Cuurent Location'),
      children: currentLocation ? (
        <div className="w-full flex flex-col gap-2 mt-5">
          <p>{locationName}</p>
        </div>
      ) : (
        'You have disbaled current location on browser'
      ),
    },
  ];

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <TitleAndBackComponent
        title={t('create-a-post')}
        goBackText={t('back')}
      />
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <div className="w-full flex flex-col gap-2 mt-2">
            <Typography value={t('post-title')} />
            <TextInput
              type="text"
              placeholder={t('title')}
              required
              id="title"
              className="flex-1"
              onChange={handleTitleChange}
            />
          </div>
          <div className="w-full flex flex-col gap-4 mt-2">
            <Typography value={t('select-a-category')} />
            <Select
              required={true}
              onChange={(e) => setFormData({ ...formData, categoryId: e })}
              options={categoryList}
              placeholder={t('select-a-category')}
              label={'name'}
              id={'_id'}
              className={'h-10 mb-3'}
            />
          </div>
        </div>
        <div className="w-full flex flex-row gap-4 ">
          <div className="w-full flex flex-col gap-4 ">
            <Typography value={t('quantity-type')} />
            <Select
              required={true}
              onChange={(e) => setFormData({ ...formData, quantityType: e })}
              options={quantityType}
              placeholder={t('select-a-quantity-type')}
              label={'name'}
              id={'_id'}
              className={'h-10'}
            />
          </div>
          <div className="w-full flex flex-col gap-4 ">
            <Typography value={t('quantity-0')} />
            <InputNumber
              placeholder={t('quantity-0')}
              required
              id="quantity"
              defaultValue={1}
              disabled={false}
              size="large"
              min={1}
              max={999}
              className="flex-1"
              onChange={handleQuantityChange}
              selectedValue={formData.quantity}
            />
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-2">
          <Typography value={t('upload-photo')} />
          <p className="text-sm text-green-400 ">
            {t(
              'images-should-be-less-than-10mb-and-should-be-in-jpg-jpeg-png-format',
            )}{' '}
          </p>
          <ImageUpload
            formData={formData}
            setFormData={(image) => handleImageChange(image)}
            loading={(e) => setLoading(e)}
            t={t}
            type="multiple"
            listType="text"
          />
        </div>
        {/* <div className="w-full flex flex-col gap-4 mt-2">
          <Typography value={t('post-type')} />
          <RadioGroup
            onChange={handleRadioChangeType}
            selectedValue={formData.type}
            t={t}
            options={[
              {
                label: t('free'),
                value: 1,
              },
              { label: t('paid'), value: 2 },
            ]}
          />
        </div> 
        <div className="w-full flex flex-col gap-4 mt-2">
          <Typography value={t('price')} />
          <InputNumber
            placeholder={t('price')}
            required
            id="$5"
            defaultValue={formData.type === 1 ? 0 : 1}
            disabled={formData.type === 1 ? true : false}
            min={formData.type === 1 ? 0 : 1}
            max={formData.type === 1 ? 0 : 999}
            className="flex-1"
            onChange={handlePriceChange}
            selectedValue={formData.price}
          />
        </div>*/}

        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Tabs defaultActiveKey={currentLocation ? '2' : '1'} items={items} />
        </div>
        {roles?.includes('admin') && (
          <div className="w-full flex flex-col gap-4 mt-2">
            <Typography value={t('status')} />
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </div>
        )}
        <div className="w-full flex flex-col gap-4 mt-2">
          <Typography value={t('description')} />
          <ReactQuill
            theme="snow"
            placeholder={t('write-something')}
            className="h-72 mb-12"
            required
            onChange={(value) => {
              setFormData({ ...formData, content: value });
            }}
          />
        </div>
        <div className="w-full flex justify-end mt-5 mb-5">
          <Button
            type="primary"
            htmlType="submit"
            className={'w-1/4'}
            loading={loading}
            disabled={loading}
          >
            {t('post-now')}
          </Button>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
      </form>
    </div>
  );
}
