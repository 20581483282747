import { FC } from 'react';
import { InputNumber } from 'antd';

interface CustomInputNumberProps {
  readOnly?: boolean;
  classNames?: string;
  defaultValue?: number;
  disabled?: boolean;
  max?: number;
  min?: number;
  onChange?: (value: number | null | undefined) => void;
  placeholder?: string;
  size?: 'large' | 'small' | 'middle';
  selectedValue?: number;
}

export const CustomInputNumber: FC<CustomInputNumberProps> = ({
  readOnly = false,
  classNames = '',
  defaultValue = 0,
  disabled = false,
  max,
  min,
  onChange,
  placeholder = '',
  size,
  selectedValue,
}) => {
  return (
    <InputNumber
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      value={selectedValue}
      readOnly={readOnly}
      max={max}
      min={min}
      size={size}
      className={classNames}
      disabled={disabled}
    />
  );
};
