import { FC, ReactNode } from 'react';
import { Card } from 'antd';

interface CustomCardProps {
  size?: 'default' | 'small';
  title?: string;
  children: ReactNode;
}

export const CustomCard: FC<CustomCardProps> = ({
  size = 'small',
  title = '',
  children,
}) => {
  return (
    <Card size={size} title={title}>
      {children}
    </Card>
  );
};
