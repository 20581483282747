import {
  TextInput,
  Select,
  Button,
  RadioGroup,
  InputNumber,
  Message,
  Typography,
  Tabs,
} from '../../utils/antd-components/index.tsx';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import PATHS from '../../utils/constants/Paths.tsx';
import {
  TitleAndBackComponent,
  ScrollToTop,
} from '../../utils/reUsable/index.tsx';
import ImageUpload from '../../utils/reUsable/ImageUpload.tsx';
import { Select as SelectAntd } from 'antd';
import { CityList } from '../../utils/constants/Citylist.tsx';

export default function UpdatePost() {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const { Option } = SelectAntd;
  // const userId = authUser._id;
  const roles = authUser.rolesType;
  const { postId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [categoryList, setCategoryList] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationName, setLocationName] = useState('');
  const [currentLocation, setCurrentLocation] = useState(false);

  /* Fetching the Post details */
  useEffect(() => {
    try {
      const fetchPost = async () => {
        const URL = `${PATHS.POSTS.INDIVIDUAL}?_id=${postId}&status=all`;
        const data = await ApiUtils(URL);

        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setFormData(data.posts);
        }
      };

      fetchPost();
      <ScrollToTop />;
      // Check if geolocation is supported
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
            // Reverse geocode the coordinates to get the location name
            try {
              const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`,
              );
              const data = await response.json();
              if (data.address) {
                setCurrentLocation(true);
                setLocationName(
                  data.address.state ||
                    data.address.city ||
                    data.address.county ||
                    'Unknown Location',
                );
              }
            } catch (error) {
              console.error('Error fetching location:', error);
            }
          },
          (error) => {
            console.error('Error getting user location:', error);
          },
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [postId]);
  /* Fetching the Post details Completed*/

  /* Fetching the Category list*/
  useEffect(() => {
    try {
      const fetchCategories = async () => {
        const URL = PATHS.CATEGORIES.GET;
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setCategoryList(data.response);
        }
      };

      fetchCategories();
    } catch (error) {
      console.log(error.message);
    }
  }, []);
  /* Fetching the Category list Completed*/

  /* Final api call were submiting the updated post */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (
        !formData.title ||
        !formData.imageUrl ||
        !formData.content ||
        !userLocation ||
        !formData.locations
      ) {
        setPublishError(t('please-fill-all-the-fields'));
        return;
      }

      // Add location coordinates to formData
      formData.locations = {
        type: 'Point',
        coordinates: [userLocation.longitude, userLocation.latitude],
      };
      const formDataToSend = {
        ...formData,
        categoryId:
          typeof formData.categoryId === 'object'
            ? formData.categoryId._id
            : formData.categoryId,
        userId:
          typeof formData.userId === 'object'
            ? formData.userId._id
            : formData.userId,
      };
      let URL;
      if (roles.includes('admin')) {
        URL = `${PATHS.POSTS.UPDATE}/${formDataToSend._id}?userId=${formDataToSend.userId}`;
      } else {
        URL = `${PATHS.POSTS.UPDATE}/${formDataToSend._id}`;
      }
      const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
      setLoading(true);

      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      } else {
        setPublishError(null);
        navigate(`/post/${data.post._id}?status=${data.post.status}`);
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };
  /* Final api call were submiting the updated post completed */

  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  // const handlePriceChange = (e) => {
  //   setFormData({ ...formData, price: e });
  // };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  // const handleRadioChangeType = (e) => {
  //   const statusValue = e.target.value;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     type: statusValue,
  //     price: statusValue === 1 ? 0 : 1,
  //   }));
  // };

  const handleReactQuillChange = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      content: value,
    }));
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, imageUrl: e });
  };

  const handleQuantityChange = (e) => {
    setFormData({ ...formData, quantity: e });
  };

  const quantityType = [
    { _id: 'kg', name: 'Kilogram' },
    { _id: 'liter', name: 'Liter' },
    { _id: 'count', name: 'Numbers' },
  ];

  const categoryId = formData?.categoryId?._id
    ? formData?.categoryId?._id
    : formData?.categoryId;

  const handleLocationChange = (cityValueString) => {
    // Set selected location
    if (!cityValueString) {
      return;
    }
    const value = JSON.parse(cityValueString);
    // Convert the array to an object with latitude and longitude keys
    const coordinates = {
      latitude: value[0],
      longitude: value[1],
    };
    setUserLocation(coordinates);
    console.log('cityvalue=====', value);

    if (coordinates) {
      // Update formData with selected location coordinates
      setFormData({
        ...formData,
        locations: {
          type: 'Point',
          coordinates: coordinates,
        },
      });
    }
  };

  const items = [
    {
      key: '1',
      label: t('Chooese you location'),
      disabled: currentLocation ? true : false,
      children: (
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <div className="w-full flex flex-col gap-2 mt-3">
            <SelectAntd
              showSearch
              style={{ width: 200 }}
              placeholder="Select a city"
              optionFilterProp="children"
              onChange={handleLocationChange} // Function to handle selection
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {CityList.map((city) => (
                <Option key={city._id} value={JSON.stringify(city.value)}>
                  {city._id}
                </Option>
              ))}
            </SelectAntd>
          </div>
        </form>
      ),
    },
    {
      key: '2',
      label: t('Your Cuurent Location'),
      children: currentLocation ? (
        <div className="w-full flex flex-col gap-2 mt-5">
          <p>{locationName}</p>
        </div>
      ) : (
        'You have disbaled current location on browser'
      ),
    },
  ];

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <TitleAndBackComponent title={t('update-post')} goBackText={t('back')} />
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('post-title')} />
          <TextInput
            type="text"
            placeholder={t('title')}
            required
            id="title"
            className="flex-1"
            onChange={handleTitleChange}
            value={formData.title}
          />
          <Typography value={t('select-a-category')} />
          <Select
            required={true}
            onChange={(e) => setFormData({ ...formData, categoryId: e })}
            options={categoryList}
            placeholder={t('select-a-category')}
            selectedValue={categoryId}
            label={'name'}
            id={'_id'}
          />
        </div>
        <Typography value={t('quantity-type')} />
        <Select
          required={true}
          onChange={(e) => setFormData({ ...formData, quantityType: e })}
          options={quantityType}
          selectedValue={formData.quantityType}
          placeholder={t('select-a-quantity-type')}
          label={'name'}
          id={'_id'}
        />
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('quantity-0')} />
          <InputNumber
            placeholder={t('quantity-0')}
            required
            id="quantity"
            defaultValue={1}
            disabled={false}
            min={1}
            max={999}
            className="flex-1"
            onChange={handleQuantityChange}
            selectedValue={formData.quantity}
          />
        </div>
        <Typography value={t('upload-photo')} />

        <ImageUpload
          formData={formData}
          setFormData={(image) => handleImageChange(image)}
          loading={(e) => setLoading(e)}
          t={t}
          type="multiple"
          listType="text"
        />

        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Tabs items={items} />
        </div>
        {roles?.includes('admin') && (
          <div className="flex flex-col gap-4 sm:flex-col justify-between">
            <Typography value={t('status')} />
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </div>
        )}
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('description')} />
          <ReactQuill
            theme="snow"
            placeholder={t('write-something')}
            className="h-72 mb-12"
            required
            onChange={handleReactQuillChange}
            value={formData.content}
          />
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('update-post')}
          </Button>
        </div>
        {publishError && <Message successMessage={publishError} type="error" />}
      </form>
    </div>
  );
}
