import { createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { clearLanguage } from '../api.tsx';

export const LanguageContext = createContext<
  | {
      t: any;
      i18n: any;
      onClickLanguageChange: (e: string) => void;
      LANGUAGELIST: { [key: string]: { nativeName: string } };
    }
  | undefined
>(undefined);

export const LanguageContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t, i18n } = useTranslation();
  const LANGUAGELIST = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Italian' },
    ta: { nativeName: t('Tamil') },
    es: { nativeName: 'Spanish' },
    zhCN: { nativeName: 'Mandarin Chinese' },
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n]);

  const onClickLanguageChange = (e: string) => {
    clearLanguage();
    const language = e;
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  return (
    <LanguageContext.Provider
      value={{ t, i18n, onClickLanguageChange, LANGUAGELIST }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => useContext(LanguageContext);
