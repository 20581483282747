import { useTranslation } from 'react-i18next';

const MaintancePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="min-h-screen flex flex-col justify-center items-center">
        <img
          src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
          alt="Logo"
          className="mb-8 h-40"
        />
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-gray-700 mb-4">
          {t('site-is-under-maintenance')}{' '}
        </h1>
        <p className="text-center text-gray-500 text-lg md:text-xl lg:text-2xl mb-8">
          {t('were-working-hard-to-improve-the-user-experience-stay-tuned')}{' '}
        </p>
      </div>
    </div>
  );
};
export default MaintancePage;
