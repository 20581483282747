/**
 * Supabase auth docs
 * resend email-verify https://supabase.com/docs/reference/javascript/auth-resend
 * other auth docs https://supabase.com/docs/reference/javascript/auth-signup
 *
 */

import { supabase } from './index.ts';

export const doCreateUserWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  return await supabase.auth.signUp({
    email: email,
    password: password,
    options: {
      emailRedirectTo: `${window.location.origin}/sign-in`,
    },
  });
};

export const doSignInWithEmailAndPassword = async (
  email: string,
  password: string,
) => {
  return await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });
};

export const doSignInWithGoogle = async () => {
  await supabase.auth.signInWithOAuth({
    provider: 'google',
  });
};
export const doUpdatePassword = async (new_password: string) => {
  await supabase.auth.updateUser({ password: new_password });
};

export const doSignOut = () => {
  return supabase.auth.signOut('local');
};

export const doPasswordReset = async (email: string) => {
  return await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/reset-password`,
  });
};

export const doPasswordChange = async (password: string) => {
  return await supabase.auth.updateUser({
    password: password,
  });
};

export const doSendEmailVerification = async (email: string) => {
  return await supabase.auth.resend({
    type: 'signup',
    email: email,
    options: {
      emailRedirectTo: `${window.location.origin}/sign-in`,
    },
  });
};
