/**
 * Pacific Blue
 * https://www.color-hex.com/color/1ca9c9
 * https://giggster.com/guide/complementary-colors/
 *
 */
class CSSUtility {
  static color = {
    primary: '#00B96B',
    complementary: '#C54B0C',
  };
  static darkcolor = {
    primary: '#00ff09',
    complementary: '#1668dc',
  };
  static header = {
    fontSize: '16px',
    margin: 0,
    height: '15px',
    fontWeight: 600,
    fontFamily: 'Lato, Helvetica, Arial, Lucida, sans-serif',
    letterSpacing: '.02em',
    lineHeight: 1.5,
    color: this.color.primary,
    textAlign: 'center',
    marginBottom: '10px',
  };
  static subtitle = {
    ...this.header,
    fontSize: '14px',
    textAlign: 'left',
  };

  static label = {
    ...this.subtitle,
    color: '#021014',
  };

  static button = {
    display: 'inline-flex',
    alignItems: 'center', // Center the content vertically
    justifyContent: 'center', // Center the content horizontally
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: this.color.primary, // Pacific Blue color
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    textAlign: 'center', // Center the text horizontally (backup)
  };

  static cancelButton = {
    ...this.button,
    backgroundColor: this.color.complementary, // Pacific Blue color
  };
}

export default CSSUtility;
